const state = {
  data: {
    tabMenu: [],
    appCmsName: "",
    appCmsUrl: "",
    appName: "",
    appMonitoringName: "",
    appMonitoringUrl: "",
  }
};

const mutations = {
  changeSurvey(state, payload) {
    state.data = Object.assign({}, state.data, payload);
  },
  LOAD_CONFIG(state) {
    const payload = {
      appName: process.env?.VUE_APP_NAME || "Provinsi",
      appCmsName: process.env?.VUE_APP_CMS_NAME || "Portal Web Sekolah",
      appCmsUrl: process.env?.VUE_APP_CMS_URL || "https://dev.portal.diskola.id/",
      appMonitoringName: process.env?.VUE_APP_MONITORING_NAME || "Dindik (DinasPendidikan)",
      appMonitoringUrl: process.env?.VUE_APP_MONITORING_URL || "https://dev.dindik.diskola.id/",
    }
    state.data = Object.assign({}, state.data, payload);
  }
};

const actions = {};

const getters = {
  getTabMenuById: state => id => {
    return state.data.tabMenu.find(item => item.id === id);
  },
  filterUserByRole: state => id => {
    return state.data.tabMenu.find(item => item.id === id);
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
