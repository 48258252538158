// EmployeeImplement.js
import moment from "moment";

moment.locale("id");

const ENDPOINT = "/employee";

export default class EmployeeImplement {
  constructor(apiService) {
    this.apiService = apiService;
  }

  async employee(data) {
    try {
      const params = this.prepareParams(data);
      if (!params) {
        throw new Error("Invalid parameters for employee.");
      }

      return await this.apiService.get(`${ENDPOINT}`, { params });
    } catch (error) {
      console.error("Error in EmployeeImplement.employee:", error);
      throw error;
    }
  }

  async detailEmployee(employeeId) {
    try {
      return await this.apiService.get(`${ENDPOINT}/${employeeId}`);
    } catch (error) {
      console.error("Error in EmployeeImplement.detailEmployee:", error);
      throw error;
    }
  }

  async employeeHaveSchools(employeId, data) {
    try {
      const params = this.prepareParamsEmployeHaveSchools(data);
      if (!params) {
        throw new Error("Invalid parameters for employeeHaveSchools.");
      }
      if (!employeId) {
        throw new Error(
          `Invalid employeId: ${employeId} for employeeHaveSchools.`
        );
      }

      //params.error = 500;
      return await this.apiService.get(`${ENDPOINT}/${employeId}/schools`, {
        params
      });
    } catch (error) {
      console.error("Error in EmployeeImplement.employeeHaveSchools:", error);
      throw error;
    }
  }

  async employeeSyncHaveSchools(employeId, formData) {
    try {
      if (typeof formData !== "object" || !formData.school_ids) {
        throw new Error(
          "formData must Object {school_ids: [valueUuid]} for employeeSyncHaveSchools."
        );
      }
      if (!employeId) {
        throw new Error(
          `Invalid employeId: ${employeId} for employeeSyncHaveSchools.`
        );
      }

      return await this.apiService.post(
        `${ENDPOINT}/${employeId}/schools`,
        formData
      );
    } catch (error) {
      console.error(
        "Error in EmployeeImplement.employeeSyncHaveSchools:",
        error
      );
      throw error;
    }
  }

  prepareParams(data) {
    const params = {};
    if (!data.government_id) {
      throw new Error(`Invalid government_id Required`);
    }

    if (data.roles) {
      if (typeof data.roles === "object" && data.roles.length) {
        params.roles = data.roles;
      }
    }

    this.validateSortField(data, params);
    this.validateSortDir(data, params);
    this.validateLimit(data, params);

    if (data.q) {
      params.q = data.q; // ops | nama employee
    }

    if (data.page) {
      params.page = parseInt(data.page); // ops | angka saja
    }

    if (data.doesnt_have_school) {
      params.doesnt_have_school = data.doesnt_have_school; // ops | 1
    }
    return params;
  }

  prepareParamsEmployeHaveSchools(data) {
    const params = {};

    this.validateLimit(data, params);
    this.validateSortField(data, params);
    this.validateSortDir(data, params);

    if (data.q) {
      params.q = data.q;
    }

    return params;
  }

  validateLimit({ limit }, params) {
    const maxLimit = 500;
    if (limit) {
      const numericLimit = parseInt(limit, 10);
      if (isNaN(numericLimit) || numericLimit > maxLimit) {
        throw new Error(
          `Invalid limit value: '${limit}'. The limit must be a number and cannot exceed ${maxLimit}.`
        );
      }
      params.limit = numericLimit;
    }
  }

  validateSortField({ sort_field }, params) {
    const validSortFields = ["name", "created_at"];
    if (sort_field && !validSortFields.includes(sort_field)) {
      throw new Error(
        `Invalid sort_field value: '${sort_field}'. Valid values are ${validSortFields.join(
          ", "
        )}.`
      );
    }
    params.sort_field = sort_field;
  }

  validateSortDir({ sort_dir }, params) {
    if (typeof sort_dir === "boolean") {
      params.sort_dir = sort_dir ? "desc" : "asc";
    } else {
      const validSortDirections = ["asc", "desc"];
      if (sort_dir && !validSortDirections.includes(sort_dir)) {
        throw new Error(
          `Invalid sort_dir value: '${sort_dir}'. Valid values are ${validSortDirections.join(
            ", "
          )}.`
        );
      }
      params.sort_dir = sort_dir;
    }
  }

  validateType({ type }, params) {
    const validTypes = ["sd", "smp", "sma", "smk", "slb"];
    if (type && !validTypes.includes(type.toLowerCase())) {
      throw new Error(
        `Invalid type value: '${type}'. Valid values are ${validTypes.join(
          ", "
        )}.`
      );
    }
    params.type = type && type.toUpperCase();
  }
}
