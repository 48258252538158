<template>
  <div class="main-content bg-primary-blue-wave py-7 py-lg-1 pt-lg-7">
    <!-- Header -->
    <div class="header">
      <div class="container">
        <div class="header-body text-center mb-2">
          <div class="row justify-content-center">
            <div class="col-lg-5 col-md-6">
              <h1 class="text-white">SELAMAT DATANG!</h1>
              <!-- <p class="text-lead text-white">
                Semoga harimu selalu menyenangkan!
              </p> -->
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="separator separator-bottom separator-skew zindex-100">
        <svg
          x="0"
          y="0"
          viewBox="0 0 2560 100"
          preserveAspectRatio="none"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <polygon
            class="fill-default"
            points="2560 0 2560 100 0 100"
          ></polygon>
        </svg>
      </div> -->
    </div>
    <!-- Page content -->
    <div class="container pb-5">
      <slide-y-up-transition mode="out-in" origin="center top">
        <router-view></router-view>
      </slide-y-up-transition>
    </div>

    <footer class="py-5">
      <div class="container">
        <div class="row align-items-center justify-content-xl-between">
          <div class="col-xl-12">
            <div class="copyright text-center text-xl-left text-white">
              &copy; {{ year }}
              <a
                  class="font-weight-bold ml-1 text-white"
                  href="https://onklas.id/"
                  target="_blank"
              >{{ configApp.appName }}</a
              >
            </div>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>
<script>
import {SlideYUpTransition} from "vue2-transitions";
import {mapState, mapMutations } from "vuex";

export default {
  name: "auth-layout",
  components: {
    SlideYUpTransition,
  },
  data() {
    return {
      year: new Date().getFullYear(),
      showMenu: false,
    };
  },
  methods: {
    ...mapMutations("configApp", ["LOAD_CONFIG"])
  },
  computed: {
    ...mapState({
      configApp: (state) => state.configApp.data
    })
  },
  mounted() {
    this.LOAD_CONFIG();
    //this.appName = process.env?.VUE_APP_NAME || "";
    //this.appCmsUrl = process.env?.VUE_APP_CMS_URL || "";
    //this.appCmsName = process.env?.VUE_APP_CMS_NAME || "";
  },
};
</script>
<style></style>
