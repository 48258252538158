import { ROLES_PETUGAS } from "@/services/employeeService";
import EmployeeImplement from "@/api/EmployeeImplement";
import ApiService from "@/utils/ApiService";
import { store } from "@/store";
import schools from "@/modules/Schools";
import Swal from "sweetalert2";
import {preLoadingSedangMemproses} from "@/services/globalService";
import InternalImplement from "@/api/InternalImplement";

const state = {
  data: {
    government_id: null,
    filterStatus: "",
    sort_field: "name",
    sort_dir: false,
    filter: "",
    items: [],
    itemsAll: [],
    itemsPurposeAll: [],
    limit: 10,
    page: 1,
    lastPage: 1,
    total: 0,
    errors: [],
    loading: false,
    loadingAllData: false,
    loadingPurposeAll: false,
    showmodal: false,
    typeAction: "read",
    detailFormData: {},
    listLimit: [10, 25, 50, 100],
    formData: {},
    detailMoveNaungan: {
      pengawasOldId: "",
      selectPengawasDestination: "",
      schoolName: "",
      schoolIndex: "",
      schoolUuId: "",
      schoolId: ""
    },
    detailPengawas: {
      loading: false,
      employeeId: null,
      detail: {},
      selectsDeleteNaungan: [],
      schools: []
    }
  }
};

const mutations = {
  removeArrayIndexDetailPengawasSchools(state, index) {
    if (state.data.detailPengawas.schools.length) state.data.detailPengawas.schools.splice(index, 1);
  },
  changeDetailMoveNaunganManajemenPengawas(state, payload) {
    state.data.detailMoveNaungan = Object.assign(
      {},
      state.data.detailMoveNaungan,
      payload
    );
  },
  changeManajemenPengawasDetailPengawas(state, payload) {
    state.data.detailPengawas = Object.assign(
      {},
      state.data.detailPengawas,
      payload
    );
  },
  changeManajemenPengawasDetailPengawasSelectsDelete(state, data) {
    // Hanya menambahkan data jika belum ada dalam array
    if (!state.data.detailPengawas.selectsDeleteNaungan.includes(data)) {
      state.data.detailPengawas.selectsDeleteNaungan.push(data);
    }
  },
  changeManajemenPengawas(state, payload) {
    state.data = Object.assign({}, state.data, payload);
  },
  changeLoading(state, payload) {
    state.data.loading = payload;
  },
  changeLoadingDetailPengawas(state, payload) {
    state.data.detailPengawas.loading = payload;
  }
};

const actions = {
  prepareSchoolsToPengawas({ getters }) {
    const arraySchoolId = getters.arraySchoolIdOfDetailPengawas;
    const {selects} = schools.state.data;
    return arraySchoolId.concat(selects)
  },
  prepareSchoolsInPengawasSync({state}) {
    const {selectsDeleteNaungan, schools} = state.data.detailPengawas;
    return schools
      .filter(item => !selectsDeleteNaungan.includes(item.id))
      .map(item => item.id);
  },

  async getManajemenPengawas({ commit, state }, payload) {
    const {
      filter,
      lastPage,
      limit,
      filterStatus,
      sort_dir,
      sort_field,
      page
    } = state.data;

    let payloadPage = payload?.page || page;
    if (payloadPage > lastPage && payload?.page) {
      console.log("STOP", payloadPage, lastPage);
      return false;
    }
    const { govermentIdGetters } = store.getters;

    const params = {
      government_id: govermentIdGetters,
      limit: limit,
      sort_field: "name",
      sort_dir: false,
      q: filter,
      roles: ROLES_PETUGAS,
      page: payloadPage
    };

    if (sort_field) {
      params.sort_field = sort_field;
      params.sort_dir = sort_dir ? "desc" : "asc";
    }

    if (filterStatus) {
      params.doesnt_have_school = 1;
    }

    try {
      commit("changeLoading", true);
      const employeeService = new EmployeeImplement(ApiService);
      const result = await employeeService.employee(params);
      commit("changeLoading", false);

      commit("changeManajemenPengawas", {
        items: result?.data?.data || [],
        total: result?.data?.meta?.total || result?.data?.data.length,
        page: result?.data?.meta?.current_page || 1,
        lastPage: result?.data?.meta?.last_page || 1
      });
      return true;
    } catch (error) {
      if (!error || error.error) {
        commit("changeManajemenPengawas", {
          items: [],
          total: 0,
          page: 1,
          lastPage: 1
        });
        return false;
      }
    }
  },

  async getManajemenPengawasAllData({ commit }) {
    const { govermentIdGetters } = store.getters;

    const params = {
      government_id: govermentIdGetters,
      sort_field: "name",
      sort_dir: false,
      roles: ROLES_PETUGAS,
    };

    try {
      commit("changeManajemenPengawas", {
        loadingAllData: true
      });
      const employeeService = new EmployeeImplement(ApiService);
      const result = await employeeService.employee(params);
      commit("changeManajemenPengawas", {
        itemsAll: result?.data?.data || [],
        loadingAllData: false
      });
      return true;
    } catch (error) {
      if (!error || error.error) {
        commit("changeManajemenPengawas", {
          itemsAll: [],
          loadingAllData: false
        });
        return false;
      }
    }
  },

  /**
   * required employeeId*/
  async getDetailPengawas({ commit, state }, employeeId) {
    if (state.data.detailPengawas.loading) {
      console.info("stop call server double");
      return;
    }
    try {
      commit("changeLoadingDetailPengawas", true);
      const employeeService = new EmployeeImplement(ApiService);
      const result = await employeeService.detailEmployee(employeeId);
      commit("changeLoadingDetailPengawas", false);
      commit("changeManajemenPengawasDetailPengawas", {
        detail: result.data.data
      });
      return true;
    } catch (error) {
      commit("changeManajemenPengawasDetailPengawas", {
        detail: {}
      });
      return false;
    }
  },

  /**
   * payload {
   *   employeeId: reuired,
   *   hideMessage: optional | default: akan tampil message
   * }
   * */
  async getDetailPengawasHaveSchools({ commit }, payload = {}) {
    const {employeeId, hideMessage} = payload;
    const params = {
      limit: 0
    };
    try {
      commit("changeLoadingDetailPengawas", true);
      const employeeService = new EmployeeImplement(ApiService);
      const result = await employeeService.employeeHaveSchools(
        employeeId,
        params
      );
      commit("changeLoadingDetailPengawas", false);
      commit("changeManajemenPengawasDetailPengawas", {
        schools: result.data.data
      });
      return true;
    } catch (error) {
      commit("changeManajemenPengawasDetailPengawas", {
        schools: []
      });
      if (!hideMessage) {
        Swal.fire("Gagal", `Proses mengambil data gagal. <br><br>${error.error}`, "error")
        return false;
      }
      return false;
    }
  },

  /**
   * payload: {
   *   employeeId: isRequired,
   *   formData: isRequired,
   *   hideMessage: optional | default:show notif
   * }
   * */
  async postSyncNaunganSchoolsManajemenPengawas({ commit, dispatch }, payload = {}) {
    const {employeeId, hideMessage} = payload;
    const formData = {
      school_ids: payload.formData
    }

    try {
      preLoadingSedangMemproses();
      commit("changeLoading", true);
      const employeeService = new EmployeeImplement(ApiService);
      await employeeService.employeeSyncHaveSchools(
        employeeId,
        formData
      );
      Swal.close();
      commit("changeLoading", false);
      commit("changeManajemenPengawasDetailPengawas", {
        selectsDeleteNaungan: []
      });
      dispatch("getDetailPengawasHaveSchools", { employeeId, hideMessage: true });
      if (!hideMessage) {
        Swal.fire("Berhasil", "Proses Binaan Sekolah Berhasil dilakukan.", "success")
        return true;
      }
      return true;
    } catch (error) {
      Swal.close();
      if (!hideMessage) {
        Swal.fire("Pemberitahuan", "Proses Binaan Sekolah Gagal dilakukan.", "error")
      }
      return false;
    }
  },

  /**
   * payload: {
   *   uuidSchool: isRequired,
   *   source_id: isRequired,
   *   destination_id: isRequired,
   *   hideMessage: optional | default:show notif
   * }
   * */
  async postMoveNaungan({ commit }, payload = {}) {
    const {uuidSchool, hideMessage, source_id,destination_id} = payload;
    const formData = {
      source_id: source_id,
      destination_id: destination_id,
    }

    try {
      preLoadingSedangMemproses();
      commit("changeLoading", true);
      const internalService = new InternalImplement(ApiService);
      await internalService.schoolMoveNaunganToEmploye(
        uuidSchool,
        formData
      );
      Swal.close();
      commit("changeLoading", false);
      commit("changeManajemenPengawasDetailPengawas", {
        selectsDeleteNaungan: []
      });
      // DELETE ARRAY IN DETAILPENGAWAS.SCHOOLS
      if (!hideMessage) {
        Swal.fire("Berhasil", "Perpindahan Binaan Sekolah Berhasil dilakukan.", "success")
        return true;
      }
      return true;
    } catch (error) {
      Swal.close();
      if (!hideMessage) {
        Swal.fire("Pemberitahuan", "Proses Perpindahan Gagal dilakukan.", "error")
      }
      return false;
    }
  },

};

const getters = {
  nameOfDetailPengawas: state => {
    const { name } = state.data.detailPengawas.detail;
    return name ? name : "Tidak ditemukan";
  },
  nipOfDetailPengawas: state => {
    const { nip } = state.data.detailPengawas.detail;
    return nip ? nip : "Tidak ditemukan";
  },
  phoneOfDetailPengawas: state => {
    const { phone } = state.data.detailPengawas.detail;
    return phone ? phone : "Tidak ditemukan";
  },
  rolesOfDetailPengawas: state => {
    const { roles } = state.data.detailPengawas.detail;
    return roles && roles.length ? roles : [];
  },
  arraySchoolIdOfDetailPengawas: state => {
    const { schools } = state.data.detailPengawas;
    return schools && schools.length ? schools.map(item => item.id) : [];
  },
  schoolsOfPengawasGetters: state => {
    const {selectsDeleteNaungan, schools} = state.data.detailPengawas;
    return schools.filter(item => {
      if (!selectsDeleteNaungan.includes(item.id)) return true;
    })
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
