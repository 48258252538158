import ApiService from "@/utils/ApiService";
import InternalImplement from "@/api/InternalImplement";

const state = {
  data: {
    sort_field: "name",
    sort_dir: false,
    filter: "",
    items: [],
    itemsAll: [],
    limit: 10,
    page: 1,
    lastPage: 1,
    total: 0,
    errors: [],
    loading: false,
  }
};

const mutations = {
  changePengawasNaungan(state, payload) {
    state.data = Object.assign(
      {},
      state.data,
      payload
    );
  },
  changeLoading(state, payload) {
    state.data.loading = payload;
  },
};

const actions = {

  async getSchoolsNaunganPengawas({ commit, state }) {

    if (state.data.loading) {
      console.log("call endpoint duplicate: STOP");
      return;
    }

    const params = {
      limit: 0
    };

    try {
      commit("changeLoading", true);
      const internalService = new InternalImplement(ApiService);
      const result = await internalService.currentSchools(params);
      commit("changeLoading", false);

      commit("changePengawasNaungan", {
        itemsAll: result?.data?.data || [],
      });
      return true;
    } catch (error) {
      commit("changeLoading", false);
      if (!error || error.error) {
        commit("changePengawasNaungan", {
          itemsAll: [],
        });
        return false;
      }
    }
  },

};

const getters = {
  handleDataItemsAllGetters: state => {
    const { filter } = state.data;
    const data = state.data.itemsAll;

    // Return the original data if there is no filter
    if (!filter) {
      return data;
    }

    // Apply the filter and return the new array
    return data.filter(item =>
      item.name.toLowerCase().includes(filter.toLowerCase())
    );
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
