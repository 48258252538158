import ApiService from "@/utils/ApiService";
import TransactionImplement from "@/api/TransactionImplement";
import moment from "moment";
import Swal from "sweetalert2";

const state = {
  data: {
    sort_field: "name",
    sort_dir: false,
    filterDate: [],
    filter: "",
    filterType: "",
    items: [],
    itemsAll: [],
    limit: 10,
    page: 1,
    lastPage: 1,
    total: 0,
    errors: [],
    loading: false,
    showmodal: false,
    typeAction: "read",
    listLimit: [10, 25, 50, 100],
    formData: {}
  }
};

const mutations = {
  changeRiwayatPP(state, payload) {
    state.data = Object.assign({}, state.data, payload);
  },
  changeLoading(state, payload) {
    state.data.loading = payload;
  }
};

const actions = {
  async getRiwayatPP({ commit, state }, payload) {
    const { lastPage, limit, filterType, page, filterDate } = state.data;

    let payloadPage = payload?.page || page;
    if (payloadPage > lastPage && payload?.page) {
      console.log("STOP", payloadPage, lastPage);
      return false;
    }

    const date = filterDate ? filterDate : [];

    if (date.length === 2) {
      const startDate = moment(date[0]);
      const endDate = moment(date[1]);
      const dayDiff = endDate.diff(startDate, "days");

      if (dayDiff > 31) {
        Swal.fire("Informasi", "Range Tanggal Maksimal maksimal 31Hari");
        return false;
      }
    }
    const params = {
      start_date: date[0],
      end_date: date[1],
      limit: limit,
      page: payloadPage
    };

    if (filterType) {
      params.type = filterType; // OUT / IN | opsional
    }

    try {
      commit("changeLoading", true);
      const transactionService = new TransactionImplement(ApiService);
      const result = await transactionService.history(params);
      commit("changeLoading", false);

      commit("changeRiwayatPP", {
        items: result?.data?.data || [],
        total: result?.data?.meta?.total || result?.data?.data.length,
        page: result?.data?.meta?.current_page || 1,
        lastPage: result?.data?.meta?.last_page || 1
      });
      return true;
    } catch (error) {
      commit("changeLoading", false);
      if (!error || error.error) {
        commit("changeRiwayatPP", {
          items: [],
          total: 0,
          page: 1,
          lastPage: 1
        });
        return false;
      }
    }
  }
};

const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
