/*!

=========================================================
* Vue Argon Dashboard - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Vue from 'vue';
import App from './App.vue';
import router from './router';
import './registerServiceWorker';
import ArgonDashboard from './plugins/argon-dashboard';
import VueProgressBar from 'vue-progressbar';
import axios from 'axios';
import Swal from 'sweetalert2';
import underscore from 'vue-underscore';
import BootstrapVue from 'bootstrap-vue';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import VueCurrencyInput from 'vue-currency-input';
import Vuetify from 'vuetify';
import Fragment from 'vue-fragment';
import { store } from './store';
import VueZoomer from 'vue-zoomer';
import columnSortable from 'vue-column-sortable';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import locale from 'element-ui/lib/locale/lang/en';
// import "vue-zoomer/dist/vue-zoomer.css";
import CKEditor from "@ckeditor/ckeditor5-vue2";

//import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import "@/assets/style.css";

Vue.config.productionTip = false;
Vue.prototype.$axios = axios;

Vue.prototype.$Swal = Swal;
const Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 1000,
});
Vue.prototype.$Toast = Toast;
Vue.use(underscore);
Vue.use(BootstrapVue);
Vue.use(VueCurrencyInput);
require('./method');
Vue.use(Vuetify);
Vue.use(Fragment.Plugin);
Vue.use(VueZoomer);
Vue.use(columnSortable);
Vue.use(ElementUI, { locale });
Vue.use(CKEditor);

window.$ = window.jQuery = require('jquery');
Vue.prototype.$jQuery = require('jquery');

Vue.use(VueProgressBar, {
  color: 'rgb(143, 255, 199)',
  failedColor: 'red',
  height: '2px',
});

router.beforeEach((to, from, next) => {
  if (to.meta.requiresAuth) {
    const authUser = JSON.parse(window.localStorage.getItem('token'));
    if (!authUser || !authUser.token) {
      next({ name: 'login' });
    } else if (to.meta.govAuth) {
      const authUser = JSON.parse(window.localStorage.getItem('token'));
      if (authUser.meta.token_type == 'goverment') {
        next();
      } else {
        next('/#/sekolah');
      }
    }
  } else if (to.meta.schoolAuth) {
    const authUser = JSON.parse(window.localStorage.getItem('token'));
    if (authUser.meta.token_type === 'school') {
      next();
    } else {
      next('/#/pemerintah');
    }
  } else {
    next();
    }
});

Vue.use(ArgonDashboard);
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
