import { store } from "@/store";

export const formDataDefault = {
  nip: null,
  name: null,
  email: null,
  phone: null,
  government_id: null,
  password: "diskola2024",
  city_id: null,
  roles: []
};

export const ROLES = {
  kadis: "Kepala Dinas",
  kacabdin: "Kepala Cabang Dinas",
  admin: "Admin",
  sd: "Gov SD",
  smp: "Gov SMP",
  sma: "Gov SMA",
  smk: "Gov SMK",
  slb: "Gov SLB"
};

export const ROLES_PETUGAS = [ROLES.sd, ROLES.smp, ROLES.sma, ROLES.smk, ROLES.slb];
export const ROLES_ADMIN = [ROLES.kadis, ROLES.kacabdin];

export const isKacabdin = (roles = []) => {
  if (roles.length) {
    return roles.some(role => role === ROLES.kacabdin);
  }
  return store.state.user.roles.some(role => role.name === ROLES.kacabdin);
};
