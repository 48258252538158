<template>
  <div class="wrapper" :class="{ 'nav-open': $sidebar.showSidebar }">
    <side-bar
      :background-color="sidebarBackground"
      short-title="Argon"
      title="Argon"
    >
      <b-dropdown id="period" variant="warning" block>
        <template slot="button-content">
          {{ "Tahun " + $store.state.selectedPeriod }}
          <i class="uil uil-angle-down"></i>
        </template>
        <b-dropdown-item
          v-for="(period, index) in $store.state.periods"
          :key="index"
          :value="period"
          @click="$store.state.selectedPeriod = period"
          >Tahun {{ period }}</b-dropdown-item
        >
      </b-dropdown>
      <template slot="links" class="text-blue font-weight-bold">
        <sidebar-item
          v-for="(item, index) in menu"
          :key="`data_${index}`"
          :link="{
            name: item.label,
            icon: item.icon,
            path: item.pathUrl
          }"
          :title="item.title"
        />
        <li class="nav-item">
          <a :href="`${urlComputed}login`" class="nav-link">
            <template>
               <i class="ni ni-album-2"></i>
              <span class="nav-link-text">Monitoring</span>
            </template>
          </a>
        </li>
      </template>
    </side-bar>
    <div class="main-content" :data="sidebarBackground">
      <DashboardNavbarDetail
        v-if="$route.meta.pageDetail"
        :backUrl="$route.meta.pageDetail"
      />
      <dashboard-navbar v-else></dashboard-navbar>

      <div @click="toggleSidebar">
        <fade-transition :duration="200" origin="center top" mode="out-in">
          <!-- your content here -->
          <router-view></router-view>
        </fade-transition>
        <content-footer v-if="!$route.meta.hideFooter"></content-footer>
      </div>
    </div>
  </div>
</template>
<script>
import DashboardNavbarDetail from "./DashboardNavbarDetail.vue";
import DashboardNavbar from "./DashboardNavbar.vue";
import ContentFooter from "./ContentFooter.vue";
import { FadeTransition } from "vue2-transitions";
import { isRoleAllowed, ListMenu } from "@/menu";

export default {
  components: {
    DashboardNavbar,
    DashboardNavbarDetail,
    ContentFooter,
    FadeTransition
  },
  data() {
    return {
      // selectedPeriods: new Date().getFullYear(),
      menu: [],
      sidebarBackground: "vue" //vue|blue|orange|green|red|primary
    };
  },
  computed: {
    showMenuSidebarComputed() {
      return true;
    },
    urlComputed() {
      return process.env.VUE_APP_MONITORING_URL;
    }
  },
  methods: {
    handleMenu() {
      const roles = this.$store.state.user.roles.map(role => role.name);

      this.menu = ListMenu().filter(menu => {
        if (isRoleAllowed(menu.roles, roles)) {
          if (menu.child && menu.child.length) {
            menu.child = menu.child.filter(child =>
              isRoleAllowed(child.roles, roles)
            );
          }
          return true;
        }
        return false;
      });
    },
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    }
  },
  mounted() {
    this.handleMenu();
  }
};
</script>
<style lang="scss"></style>
