import Vue from "vue";
// import axios from 'axios'
// import numeral from "numeral";
import numeral from "numeral";
import moment from "moment";
// import { _ } from "vue-underscore";
import 'moment/locale/id'; // Pastikan ini diimpor untuk mendapatkan lokalisasi Bahasa Indonesia

let loadingInstance;
const globalMethods = Vue.mixin({
  methods: {
    showLoading(text = "Loading", target = false) {
      loadingInstance = {};
      let options = {
        text: text,
        lock: true,
      };

      if (target) {
        options["target"] = target;
      }

      loadingInstance = this.$loading(options);
    },
    closeLoading() {
      setTimeout(() => {
        loadingInstance.close();
      }, 1000);
    },
    getImgUrl(pic) {
      return require("./assets/image/icons/" + pic);
    },
    formatDateDefault(date) {
      if (date == null) {
        return null;
      }
      return moment(date).format("YYYY-MM-DD");
    },
    formatDateSummary(date) {
      if (date == null) {
        return null;
      }
      return moment(date).format("DD/MM/YYYY");
    },
    formatDate(date) {
      moment.locale("id");
      return moment(date, "MMMM Do YYYY, h:mm:ss a").format(
        "MMMM Do YYYY, h:mm:ss a"
      );
    },
    handleErrorApi(errorMessage, returnType = "array") {
      if (errorMessage) {
        let sequence = 1;
        let message = [];
        let messageString = [];

        for (const [key, value] of Object.entries(errorMessage)) {
          let alertTemp = {
            index: sequence,
            key: key,
            value: value[0],
          };

          message.push(alertTemp);
          messageString.push(value[0]);
        }

        if (returnType == "string") {
          return messageString.join();
        }

        return message;
      }

      return "Opps! Ada kesalahan";
    },
    formatCurrency(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    changeFormatTime: function(data) {
      if (data) {
        return moment(data).format("HH:mm");
      } else {
        return "";
      }
    },
  },
  filters: {
    changeAlphabet: function(data) {
      const alphabet = [
        "a",
        "b",
        "c",
        "d",
        "e",
        "f",
        "g",
        "h",
        "i",
        "j",
        "k",
        "l",
        "m",
        "n",
        "o",
        "p",
        "q",
        "r",
        "s",
        "t",
        "u",
        "v",
        "w",
        "x",
        "y",
        "z",
      ];

      if (alphabet[data]) {
        return alphabet[data].toUpperCase();
      } else {
        return "";
      }
    },
    substringText: function(text, length = 100) {
      if (text.length <= length) {
        return text;
      }
      return text.substring(0, length) + ". . .";
    },
    formatDateTime: function(date) {
      if (date) {
        return moment(date).format("DD MMMM YYYY");
      } else {
        return null;
      }
    },
    formatDateTimeDayIndonesia: function(date) {
      return date ? moment(date).locale('id').format("DD MMMM YYYY HH:mm") : null;
    },
    shortDateTime: function(date) {
      if (date) {
        return moment(date).format("DD MMM YYYY");
      } else {
        return null;
      }
    },
    formatCurrency(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    formatDateModal: function(date) {
      if (date) {
        return moment(date, "MM-DD-YYYY").format("DD MMMM YYYY");
      } else {
        return null;
      }
    },
    formatNumber(value) {
      let formatted = numeral(value).format("0,0");
      return formatted;
    },
    romanizeNumber(num) {
      if (isNaN(num)) return NaN;
      var digits = String(+num).split(""),
        key = [
          "",
          "C",
          "CC",
          "CCC",
          "CD",
          "D",
          "DC",
          "DCC",
          "DCCC",
          "CM",
          "",
          "X",
          "XX",
          "XXX",
          "XL",
          "L",
          "LX",
          "LXX",
          "LXXX",
          "XC",
          "",
          "I",
          "II",
          "III",
          "IV",
          "V",
          "VI",
          "VII",
          "VIII",
          "IX",
        ],
        roman = "",
        i = 3;
      while (i--) roman = (key[+digits.pop() + i * 10] || "") + roman;
      return Array(+digits.join("") + 1).join("M") + roman;
    },
    formatDate(date) {
      moment.locale("id");
      return moment(date).format("MMMM Do YYYY, h:mm");
    },
  },
});

export default globalMethods;
