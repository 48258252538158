<template>
  <nav
    class="navbar navbar-vertical fixed-left navbar-expand-md navbar-light bg-white"
    id="sidenav-main"
  >
    <div class="container-fluid">
      <!--Toggler-->
      <navbar-toggle-button @click.native="showSidebar">
        <span class="navbar-toggler-icon"></span>
      </navbar-toggle-button>

      <router-link class="navbar-brand" :to="{name: 'generateToken'}">
        <img
          v-if="$store.state.user.government.image == null"
          class="mr-2"
          :src="require('@/assets/image/logo/logo-app.png')"
          alt="logo"
          v-once
          lazy="loading"
        />
        <img
          v-else
          class="mr-2"
          :src="$store.state.user.government.image"
          alt="logo"
          v-once
          lazy="loading"
        />
        <!-- Validasi jika tidak ada name city goverment  -->
        <span class="font-weight-bold">{{
          $store.state.user.government.city == null
            ? $store.state.user.name.slice(0, 13) + "..."
            : $store.state.user.government.city.name.slice(0, 13) + "..."
        }}</span>
      </router-link>

      <slot name="mobile-right">
        <ul class="nav align-items-center d-md-none">
          <base-dropdown class="nav-item" position="right">
            <a
              slot="title"
              class="nav-link nav-link-icon"
              href="#"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <i class="ni ni-bell-55"></i>
            </a>

            <a class="dropdown-item" href="#">Action</a>
            <a class="dropdown-item" href="#">Another action</a>
            <div class="dropdown-divider"></div>
            <a class="dropdown-item" href="#">Something else here</a>
          </base-dropdown>
          <base-dropdown class="nav-item" position="right">
            <a slot="title" class="nav-link" href="#" role="button">
              <div class="media align-items-center">
                <span class="avatar avatar-sm rounded-circle">
                  <img
                    v-if="$store.state.user.government.image == null"
                    class=""
                    :src="require('@/assets/image/logo/logo-app.png')"
                    alt=""
                  />
                  <img
                    v-else
                    class=""
                    :src="$store.state.user.government.image"
                    alt=""
                  />
                </span>
              </div>
            </a>

            <div class=" dropdown-header noti-title">
              <h6 class="text-overflow m-0">Welcome!</h6>
            </div>

            <div class="dropdown-divider"></div>
            <a href="#" @click="doLogout" class="dropdown-item">
              <i class="ni ni-user-run"></i>
              <span>Logout</span>
            </a>
          </base-dropdown>
        </ul>
      </slot>
      <slot></slot>
      <div
        v-show="$sidebar.showSidebar"
        class="navbar-collapse collapse show"
        id="sidenav-collapse-main"
      >
        <div class="navbar-collapse-header d-md-none">
          <div class="row">
            <div class="col-6 collapse-brand">
              <router-link to="/">
                <img src="" />
              </router-link>
            </div>
            <div class="col-6 collapse-close">
              <navbar-toggle-button
                @click.native="closeSidebar"
              ></navbar-toggle-button>
            </div>
          </div>
        </div>

        <ul class="navbar-nav">
          <slot name="links"> </slot>
        </ul>
        <!--Divider-->
        <hr class="my-3" />
        <!--Heading-->
      </div>
    </div>
  </nav>
</template>
<script>
import NavbarToggleButton from "@/components/NavbarToggleButton";

export default {
  name: "sidebar",
  components: {
    NavbarToggleButton,
  },
  data() {
    return {
      dataUser: JSON.parse(localStorage.getItem("dataUser")),
      checkToken: window.localStorage.getItem("token"),
    };
  },
  props: {
    logo: {
      type: String,
      default: "img/brand/Logo-pemprov.png",
      description: "Sidebar app logo",
    },
    autoClose: {
      type: Boolean,
      default: true,
      description:
        "Whether sidebar should autoclose on mobile when clicking an item",
    },
  },
  provide() {
    return {
      autoClose: this.autoClose,
    };
  },
  methods: {
    closeSidebar() {
      this.$sidebar.displaySidebar(false);
    },
    showSidebar() {
      this.$sidebar.displaySidebar(true);
    },
    doLogout() {
      this.$store.dispatch("updateUser", {});
      this.$store.dispatch("updateToken", "");
      localStorage.removeItem("token");
      this.$Toast.fire({
        type: "success",
        title: "Session destroy",
      });
      // this.$router.push("/");
      window.location.href = "/";
    },
  },
  beforeDestroy() {
    if (this.$sidebar.showSidebar) {
      this.$sidebar.showSidebar = false;
    }
  },
};
</script>
