<template>
  <transition name="fade">
    <div class="modal " id="myModal" style="display: block;">
      <div class="modal-dialog modal-sm ">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title text-dark">UBAH PASSWORD</h4>
            <button
              type="button"
              class="close"
              @click="close"
              data-dismiss="modal"
            >
              [x] Tutup
            </button>
          </div>
          <!-- Modal body -->
          <div class="modal-body pl-5 pr-5 pb-5">
            <div class="row">
              <div class="col-md-12">
                <form @submit.prevent="submitChangePassword">
                  <div class="form-group ">
                    <label for="passwordOld">Masukan password lama</label>
                    <el-input
                      placeholder="Password Lama" v-model="passwordOld"
                      show-password></el-input>
                    <label
                      for=""
                      id="errorMessageOld"
                      style="display:none;"
                      class=""
                      >{{ errorMessageOld }}</label
                    >
                  </div>
                  <div class="form-group ">
                    <label for="passwordNew">Input password baru</label>
                    <el-input
                      placeholder="Password Baru" v-model="passwordNew"
                      show-password></el-input>
                  </div>
                  <div class="form-group ">
                    <label for="passwordConfirm"
                      >Konfirmasi password baru</label
                    >
                    <el-input
                      placeholder="Password Baru" v-model="confirmPassword"
                      show-password></el-input>
                    <label
                      for=""
                      style="display:none;"
                      id="errorMessage"
                      class=""
                      >{{ errorMessageConfirm }}</label
                    >
                  </div>

                  <div class="change-password">
                    <button type="submit" class=" w-100 btn btn-success">
                      UBAH SEKARANG
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>

          <!-- Modal footer -->
          <!-- <div class="modal-footer">
            <button
              type="button"
              @click="$emit('close')"
              class="btn btn-danger"
              data-dismiss="modal"
            >
              Close
            </button>
          </div> -->
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
import broker from "../../../../utils/broker";
import Swal from "sweetalert2";

export default {
  name: "ModalUbahPassword",
  data() {
    return {
      passwordOld: "",
      passwordNew: "",
      confirmPassword: "",
      errorMessageConfirm: "",
      errorMessageOld: "",
      visibilityPasswordConfirm: "password",
      visibilityPasswordNew: "password",
      visibilityPasswordOld: "password",
    };
  },
  methods: {
    close() {
      this.$emit("close");
    },
    switchVisibilityConfirm() {
      this.visibilityPasswordConfirm =
        this.visibilityPasswordConfirm === "password" ? "text" : "password";
    },
    switchVisibilityPassNew() {
      this.visibilityPasswordNew =
        this.visibilityPasswordNew === "password" ? "text" : "password";
    },
    switchVisibilityPassOld() {
      this.visibilityPasswordOld =
        this.visibilityPasswordOld === "password" ? "text" : "password";
    },
    submitChangePassword() {
      let self = this;
      try {
        // const swalWithBootstrapButtons = Swal.mixin({
        //   customClass: {
        //     confirmButton: "btn btn-primary",
        //     cancelButton: "btn btn-outline-primary",
        //   },
        //   buttonsStyling: false,
        // });

        if (self.passwordNew != self.confirmPassword) {
          let passwordConfirm = document.getElementById("passwordConfirm");
          let errorMessage = document.getElementById("errorMessage");
          let visibilityConfirm = document.getElementById("visibilityConfirm");

          passwordConfirm.classList.add("error-confirm-password");
          errorMessage.classList.add("error-message");
          visibilityConfirm.classList.add("error-visibility");

          self.errorMessageConfirm = "Password konfirmasi anda tidak sama ";
        } else {
          // swalWithBootstrapButtons.fire({
          //   title: "PASSWORD AKAN DIPERBARUI",
          //   text:
          //     "Apa anda yakin untuk merubah password login?, jika yakin silahkan melanjutkan dan konfirmasi perubahan",
          //   showCancelButton: true,
          //   confirmButtonText: "KONFIRMASI",
          //   cancelButtonText: "TIDAK, BATALKAN",
          //   reverseButtons: true,
          // });
          const formData = {
            password_old: self.passwordOld,
            password_new: self.passwordNew,
          };

          broker.fetch
            .post(`/profile/change-password`, formData)
            .then(() => {
              Swal.fire({
                title: "PASSWORD BERHASIL DIPERBARUI",
                imageWidth: 99,
                imageHeight: 99,
                text:
                  "Password login anda telah diperbarui, silahkan login kembali dengan memasukan password terbaru",
                imageUrl: "/img/password_checked.12e91a58.svg",
              }).then(() => this.close());
            })
            .catch((error) => {
              // let message = "Opps! Ada kesalahan.";
              if (error.response) {
                if (error.response.data.error) {
                  self.errorMessageOld = `Password lama anda salah, mohon cek kembali`;
                  let errorMessageOld = document.getElementById(
                    "errorMessageOld"
                  );
                  let passwordOld = document.getElementById("passwordOld");
                  let visibilityOld = document.getElementById("visibilityOld");

                  passwordOld.classList.add("error-confirm-password");
                  visibilityOld.classList.add("error-visibility");
                  errorMessageOld.classList.add("error-message");
                }

                // self.$Swal.fire("Opps!", message, "error");
              }
            });
        }
      } catch (error) {
        let message = "Opps! Ada kesalahan.";

        if (error.response) {
          if (error.response.data.errors) {
            message = this.handleErrorApi(error.response.data.errors, "string");
          }

          Swal.fire("Alert", message, "warning");
        }
      } finally {
        this.loading = false;
      }
    },
  },
  activated() {},
  mounted() {},
};
</script>
