import Swal from "sweetalert2";

export const onNextPageEventHandler = (page, action) => {
  action({page: page + 1});
};
export const onPrevPageEventHandler = (page, action) => {
  action({page: page - 1});
};

export const preLoadingSedangMemproses = () => {
  Swal.fire({
    title: "Sedang Memproses...",
    didOpen: () => {
      Swal.showLoading();
    },
  });
}
