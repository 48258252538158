import { ApiGetRequest } from "@/utils/broker";
import { Message } from "element-ui";
import moment from "moment";

const state = {
  data: {
    page: 1,
    limit: 10,
    total: 0,
    q: "",
    items_prokes_partisipasi: [],
    items_prokes_keaktifan: [],
    loading: false,
    type: "",
    start_date: "",
    end_date: "",
    modal_filter: {
      start_date: "",
      end_date: "",
    },
    modal_keaktifan: {
      start_date: "",
      end_date: "",
      sort_dir: "desc",
    },
  },
};

const mutations = {
  changeProkesPartisipasi(state, payload) {
    state.data = Object.assign({}, state.data, payload);
  },
};

const actions = {
  async getProkesPartisipasi({ commit, state }) {
    commit("changeProkesPartisipasi", {
      loading: true,
    });
    const { data } = state;

    let params = {
      page: data.page,
      limit: data.limit,
      q: data.q,
      type: data.type,
      // start_date: data.start_date,
    };

    if (data.start_date && data.start_date != "") {
      params["start_date"] = moment(data.start_date).format("YYYY-MM-DD");
    }

    if (data.end_date && data.end_date != "") {
      params["end_date"] = moment(data.end_date).format("YYYY-MM-DD");
    }

    const result = await ApiGetRequest(
      `health-protocol/school/participation`,
      params
    );

    if (result.error) {
      Message({
        type: "error",
        message: result.error,
      });
    } else {
      commit("changeProkesPartisipasi", {
        items_prokes_partisipasi: result.data.data,
        total: result.data.meta
          ? result.data.meta.total
          : result.data.data.length,
        loading: false,
      });

      return result.data.data;
    }
  },

  async getDetailProkesPartisipasi({ commit }, payload) {
    commit("changeProkesPartisipasi", {
      loading: true,
    });

    const result = await ApiGetRequest(
      `health-protocol/school/participation/${payload.id}`
    );

    console.log(`==r=esult`, result);
    if (result.error) {
      Message({
        type: "error",
        message: result.error,
      });

      commit("changeProkesPartisipasi", {
        loading: false,
      });

      return [];
    } else {
      commit("changeProkesPartisipasi", {
        data_detail: result.data.data,
        loading: false,
      });
    }
  },

  async getDetailHistoryProkesPartisipasi({ commit }, payload) {
    commit("changeProkesPartisipasi", {
      loading: true,
    });
    const { data } = state;

    let params = {};

    if (data.modal_filter.start_date && data.modal_filter.start_date != "") {
      params["start_date"] = moment(data.modal_filter.start_date).format(
        "YYYY-MM-DD"
      );
    }

    if (data.modal_filter.end_date && data.modal_filter.end_date != "") {
      params["end_date"] = moment(data.modal_filter.end_date).format(
        "YYYY-MM-DD"
      );
    }

    const result = await ApiGetRequest(
      `health-protocol/school/participation/${payload.id}/history`,
      params
    );

    if (result.error) {
      Message({
        type: "error",
        message: result.error,
      });

      commit("changeProkesPartisipasi", {
        loading: false,
      });

      return [];
    } else {
      commit("changeProkesPartisipasi", {
        data_history: result.data.data,
        loading: false,
      });
    }
  },

  async getDetailKeaktifanProkesPartisipasi({ commit, state }) {
    commit("changeProkesPartisipasi", {
      loading: true,
    });

    const { data } = state;

    let params = {
      sort_dir: data.modal_keaktifan.sort_dir,
    };

    if (
      data.modal_keaktifan.start_date &&
      data.modal_keaktifan.start_date != ""
    ) {
      params["start_date"] = moment(data.modal_keaktifan.start_date).format(
        "YYYY-MM-DD"
      );
    }

    if (data.modal_keaktifan.end_date && data.modal_keaktifan.end_date != "") {
      params["end_date"] = moment(data.modal_keaktifan.end_date).format(
        "YYYY-MM-DD"
      );
    }

    const result = await ApiGetRequest(
      `health-protocol/school/participation/percentage`,
      params
    );

    if (result.error) {
      Message({
        type: "error",
        message: result.error,
      });

      commit("changeProkesPartisipasi", {
        loading: false,
      });

      return [];
    } else {
      commit("changeProkesPartisipasi", {
        items_prokes_keaktifan: result.data.data,
        loading: false,
      });
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
