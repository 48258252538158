import { Message, MessageBox } from 'element-ui';
import Swal from "sweetalert2";

export const HandlerResult = (result = null, message = null) => {
  if (result.data === "") {
    Message({
      type: "error",
      message: `Response ${message} Tidak Ditemukan. Ini disebabkan ketika mengambil data ke server, server tidak mengirim response apapun.`
    });
    return false;
  }
  return true;
};

export const copyTeksInput = (teks) => {
  var TempText = document.createElement("input");
  TempText.value = teks;
  document.body.appendChild(TempText);
  TempText.select();

  document.execCommand("copy");
  document.body.removeChild(TempText);

  Message({
    type: "success",
    message: "Berhasil disalin.",
  });
};

export const HandlerAlertUnauthorized = () => {
  MessageBox.alert(
    `Login sudah <strong>kadalwarsa</strong> atau <strong>Akun ini sudah di non akatifkan</strong>. Anda akan diarahkan ke halaman LOGIN.`,
    "PEMBERITAHUAN",
    {
      closeOnClickModal: true,
      closeOnPressEscape: true,
      confirmButtonText: "OK",
      dangerouslyUseHTMLString: true,
      callback: function() {
        localStorage.clear();
        localStorage.removeItem("vuex");
        localStorage.removeItem("token");
        window.location = "/login";
      }
    }
  );
  return true;
};

export const ShowLoadingSwal = () => {
  Swal.fire({
    title: 'Mohon Tunggu...',
    html: 'Data sedang diproses.',
    timerProgressBar: true,
    didOpen: () => {
      Swal.showLoading()
    },
    customClass: {
      container: 'swal-z-index-fix'
    },
    willOpen: (element) => {
      element.style.zIndex = '3551'; // Sesuaikan nilai ini agar lebih tinggi dari modal
    },
    showConfirmButton: false,
    allowOutsideClick: false,
    allowEscapeKey: false,
    allowEnterKey: false
  });
}
