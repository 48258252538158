import { ApiGetRequestManual } from '@/utils/broker';
import { Message } from 'element-ui';

const state = {
  data: {
    cities: [],
  },
};

const mutations = {
  changeCities(state, payload) {
    state.data = Object.assign({}, state.data, payload);
  },
};

const actions = {
  /**
   * query: province_id is reuired
   * */
  async getCities({ commit }, province_id = null) {
    if (province_id && province_id !== '') {
      const result = await ApiGetRequestManual(
        `/city?province_id=${province_id}`
      );
      if (result.error) {
        Message({
          type: 'error',
          message: result.error,
        });
      } else {
        commit('changeCities', {
          cities: result.data.data,
        });

        return result.data.data;
      }
    } else {
      return [];
    }
  },
};

const getters = {
  citiesGetters: (state) => {
    if (!state.data.cities.length) return [];
    return state.data.cities.map(item => {
      return {
        value: item.id,
        label: item.name
      }
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
