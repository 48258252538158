import { ApiGetRequest, ApiPostRequest } from "@/utils/broker";
import { Message } from "element-ui";
import { HandlerResult } from "@/utils/Api";
import { formDataDefault } from "@/services/unitDepartmentService";

const state = {
  data: {
    items: [],
    itemsAll: [],
    errors: [],
    limit: 1000,
    page: 1,
    total: 0,
    limitAreaCoverage: 5,
    loading: false,
    showmodal: false,
    searchName: "",
    status: "active",
    detailFormData: {},
    formData: {
      ...formDataDefault
    },
    selectTypeSchool: [
      { value: "Gov SMA", label: "SMA" },
      { value: "Gov SMK", label: "SMK" },
      { value: "Gov SLB", label: "SLB" }
    ],
  }
};

const mutations = {
  changeUnitDepartement(state, payload) {
    state.data = Object.assign({}, state.data, payload);
  },

  /**
   * Proses dimana melakukan penambahan data ke items.
   *
   * @function
   * @param {Object} payload - Harus berupa objek. Data yang akan ditambahkan ke items.
   * @param {Object} state - State saat ini.
   * @returns {void}
   */
  ADD_ITEMS(state, payload) {
    state.data.items.push(payload);
  }
};

const actions = {
  async getUnitDepartment({ commit, state }, payload) {

    if (state.data.loading) {
      console.log("call enpoint duplicate: STOP");
      return false;
    }

    const params = {
      limit: state.data.limit,
      page: payload?.page || state.data.page
    };

    if (state.data.searchName) params.q = state.data.searchName;

    commit("changeUnitDepartement", {
      loading: true
    });
    const result = await ApiGetRequest("/unit-department", params);
    commit("changeUnitDepartement", {
      loading: false
    });

    if (result.error || !result) {
      commit("changeUnitDepartement", {
        items: [],
        total: 0
      });
      return false;
    }

    commit("changeUnitDepartement", {
      items: result?.data?.data || [],
      total: result?.data?.meta?.total || result?.data?.data.length
    });
    return true;
  },

  async postUnitDepartment({ commit, state, rootGetters }) {
    const {
      area_coverage,
      email,
      name,
      phone,
      nip,
      password
    } = state.data.formData;
    const formData = {
      province_id: rootGetters.provinceIdGetters,
      city_id: area_coverage[0],
      area_coverage,
      email,
      name,
      phone,
      nip: nip.replace(/\s/g, ''),
      password
    };

    commit("changeUnitDepartement", {
      loading: true
    });
    const result = await ApiPostRequest("/unit-department", formData);
    commit("changeUnitDepartement", {
      loading: false
    });

    if (result.error || !result) {
      if (result.errorList) {
        let errors = [];
        for (const [key, value] of Object.entries(result.errorList)) {
          errors.push({
            message: value[0],
            path: [key]
          });
        }
        commit("changeUnitDepartement", {
          errors: errors
        });
        return false;
      }
      Message({
        type: "error",
        message: result.error || "Gagagal diproses"
      });
      return false;
    }
    Message({
      type: "success",
      message: "Berhasil diproses"
    });
    if (!HandlerResult(result, "Post Unit Department")) {
      console.log("unit Department empty response");
      return true;
    }
    commit("ADD_ITEMS", result.data.data);
    commit("changeUnitDepartement", { formData: formDataDefault });
    return true;
  }
};

const getters = {
  unitDepartmentGetters: state => {
    return state.data.items;
  },
  groupAreaConverageMergedGetters: state => {
    return state.data.items
      .map(item => item.area_coverage)
      .reduce((acc, curr) => {
        if (Array.isArray(curr)) {
          return [...acc, ...curr];
        }
        return acc;
      }, []);
  },
  udCitiesGetters: (state, getters, rootState, rootGetters) => {
    const mergedAreaCoverage = getters.groupAreaConverageMergedGetters || [];
    const cities = rootGetters["cities/citiesGetters"] || [];

    return cities.filter(city => !mergedAreaCoverage.includes(city.value));
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
