<template>
  <transition name="fade">
    <div
      class="modal "
      id="myModal"
      style="display: block;
"
    >
      <div class="modal-dialog modal-lg ">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title text-dark">PROFILE</h4>
            <button
              type="button"
              class="close"
              @click="close"
              data-dismiss="modal"
            >
              [x] Tutup
            </button>
          </div>
          <!-- Modal body -->
          <div class="modal-body">
            <div class="row">
              <div class="col-md-3 ">
                <div class="image-profile text-center ">
                  <img
                    style="object-fit: cover;"
                    v-if="$store.state.user.government.image == null"
                    class="border"
                    :src="require('@/assets/image/logo/logo-app.png')"
                    alt="logo-app"
                  />
                  <img
                    v-else
                    class=""
                    :src="$store.state.user.government.image"
                    alt=""
                  />
                </div>
              </div>
              <div class="col-md-9">
                <div class="overflow-auto w-100">
                  <div class="biodata">
                    <h3 class="text-primary-blue">BIODATA</h3>

                    <div class="form-row">
                      <div class="form-group col-md-6">
                        <label for="namalengkap">Nama lengkap</label>
                        <input
                          readonly
                          type="text"
                          class="form-control bg-white"
                          id="namalengkap"
                          :value="$store.state.user.name"
                        />
                      </div>
                      <div class="form-group col-md-6">
                        <label for="nisn">Provinsi</label>
                        <input
                          readonly
                          type="text"
                          class="form-control bg-white"
                          id="nisn"
                          :value="$store.state.user.government.province.name"
                        />
                      </div>
                    </div>
                    <div class="form-row">
                      <div class="form-group col-md-6">
                        <label for="Email">Email</label>
                        <input
                          readonly
                          type="text"
                          class="form-control bg-white"
                          id="Email"
                          :value="$store.state.user.email"
                        />
                      </div>
                      <div class="form-group col-md-6">
                        <label for="Telepon">Telepon</label>
                        <input
                          readonly
                          type="text"
                          class="form-control bg-white"
                          id="Telepon"
                          :value="$store.state.user.phone"
                        />
                      </div>
                    </div>
                    <div class="form-row">
                      <div class="form-group col-md-6"></div>
                      <div class="form-group col-md-6">
                        <a
                          @click="openModal()"
                          class="btn w-100 btn-outline-primary"
                        >
                          Ubah Password
                        </a>
                        <ModalUbahPassword
                          v-if="modalVisible"
                          @close="modalVisible = false"
                          class="overlay"
                        ></ModalUbahPassword>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- Modal footer -->
          <!-- <div class="modal-footer">
            <button
              type="button"
              @click="$emit('close')"
              class="btn btn-danger"
              data-dismiss="modal"
            >
              Close
            </button>
          </div> -->
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
// import broker from "../../../../utils/broker";
import ModalUbahPassword from "../../Modal/Profile/ModalUbahPassword";
export default {
  name: "ModalBiodataPelajar",

  components: {
    ModalUbahPassword,
  },
  data() {
    return {
      modalVisible: false,
    };
  },
  methods: {
    close() {
      this.$emit("close");
    },
    openModal() {
      this.modalVisible = true;
    },
  },
  activated() {},
  mounted() {},
};
</script>
