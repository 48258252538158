import axios from "axios";
import { store } from "@/store";
import { HandlerAlertUnauthorized } from "./Api";

var token = window.localStorage.getItem("token");
const CancelToken = axios.CancelToken;
let cancel;

const instance = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  timeout: 20000,
  headers: {
    Authorization: `Bearer ` + token,
    Accept: "application/json",
    "Content-Type": "application/json"
  },
  cancelToken: new CancelToken(function executor(c) {
    cancel = c;
  })
});

const instanceManual = axios.create({
  baseURL: process.env.VUE_APP_API_URL_MANUAL,
  timeout: 20000,
  headers: {
    Authorization: `Bearer ` + token,
    Accept: "application/json",
    "Content-Type": "application/json"
  },
  cancelToken: new CancelToken(function executor(c) {
    cancel = c;
  })
});

const instanceMultipart = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  timeout: 20000,
  headers: {
    Authorization: `Bearer ` + token,
    Accept: "application/json",
    "Content-Type": "multipart/form-data"
  },
  cancelToken: new CancelToken(function executor(c) {
    cancel = c;
  })
});

const instanceRkas = axios.create({
  baseURL: process.env.VUE_APP_API_URL_RKAS,
  timeout: 20000,
  headers: {
    Authorization: `Bearer ` + token,
    Accept: "application/json",
    "Content-Type": "application/json"
  },
  cancelToken: new CancelToken(function executor(c) {
    cancel = c;
  })
});

const setToken = value => {
  let item = localStorage.getItem("token");
  token = item ?? value;
};

export function ApiGetRequestManual(url, data = {}) {
  return instanceManual
    .get(url, {
      params: data
    })
    .then(response => response)
    .then(responseJson => {
      return responseJson;
    })
    .catch(error => {
      if (error.code === 'ECONNABORTED' && error.message.includes('timeout')) {
        // Handle timeout error
        console.log('Timeout error: Request took too long to complete');
        return {
          error: "Mengambil data terlalu lama. Silakan coba lagi.",
          code: 408 // HTTP status code untuk timeout
        };
      }
      try {
        return {
          error: (error.response && error.response.data.message) || error,
          errorList: error.response && error.response.data && error.response.data.errors,
          code: error.response ? error.response.status : 500
        };
      } catch (e) {
        return {
          error:
            "Maaf, terjadi kesalahan saat mengakses data. Silakan coba lagi nanti.",
          errorList: [],
          code: 500
        };
      }
    });
}

export function ApiGetRequest(url, data = {}) {
  return instance
    .get(url, {
      params: data
    })
    .then(response => response)
    .then(responseJson => {
      return responseJson;
    })
    .catch(async error => {
      if (error.code === 'ECONNABORTED' && error.message.includes('timeout')) {
        // Handle timeout error
        console.log('Timeout error: Request took too long to complete');
        return {
          error: "Mengambil data terlalu lama. Silakan coba lagi.",
          code: 408 // HTTP status code untuk timeout
        };
      }
      try {
        if (error.response.status === 401) {
          if (store.state.stopRequest) {
            return {
              error: "Silahkan Login Ulang."
            };
          } else {
            await store.commit("updateState", {
              stopRequest: true
            });
            await HandlerAlertUnauthorized();
            return {
              error: "Silahkan Login Ulang."
            };
          }
        }
        return {
          error: (error.response && error.response.data.error) ? error.response.data.error : (error.response && error.response.data.message),
          errorList:
            error.response && error.response.data && error.response.data.errors,
          code: error.response ? error.response.status : 500
        };
      } catch (e) {
        return {
          error:
            "Maaf, terjadi kesalahan saat mengakses data. Silakan coba lagi nanti.",
          errorList: [],
          code: 500
        };
      }
    });
}

export function ApiPostMultipart(url, data = {}) {
  return instance
    .post(url, data, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    })
    .then(response => response)
    .then(responseJson => {
      return responseJson;
    })
    .catch(error => {
      if (error.code === 'ECONNABORTED' && error.message.includes('timeout')) {
        // Handle timeout error
        console.log('Timeout error: Request took too long to complete');
        return {
          error: "Mengambil data terlalu lama. Silakan coba lagi.",
          code: 408 // HTTP status code untuk timeout
        };
      }
      return {
        error: (error.response && error.response.data.message) || error,
        errorList:
          error.response && error.response.data && error.response.data.errors
      };
    });
}

export function ApiPutMultipart(url, data = {}) {
  return instance
    .put(url, data, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    })
    .then(response => response)
    .then(responseJson => {
      return responseJson;
    })
    .catch(error => {
      if (error.code === 'ECONNABORTED' && error.message.includes('timeout')) {
        // Handle timeout error
        console.log('Timeout error: Request took too long to complete');
        return {
          error: "Mengambil data terlalu lama. Silakan coba lagi.",
          code: 408 // HTTP status code untuk timeout
        };
      }
      return {
        error: (error.response && error.response.data.message) || error,
        errorList:
          error.response && error.response.data && error.response.data.errors
      };
    });
}

export function ApiGetUploadFile(url, data = {}, callbackUpload = () => {}) {
  return instance
    .post(url, data, {
      headers: {
        "Content-Type": "multipart/form-data"
      },
      onUploadProgress: event => callbackUpload(event)
    })
    .then(response => response)
    .then(responseJson => {
      return responseJson;
    })
    .catch(error => {
      if (error.code === 'ECONNABORTED' && error.message.includes('timeout')) {
        // Handle timeout error
        console.log('Timeout error: Request took too long to complete');
        return {
          error: "Mengambil data terlalu lama. Silakan coba lagi.",
          code: 408 // HTTP status code untuk timeout
        };
      }
      return {
        error: (error.response && error.response.data.message) || error,
        errorList:
          error.response && error.response.data && error.response.data.errors
      };
    });
}

export const ApiPostRequest = (url, data = {}) => {
  return instance
    .post(url, data)
    .then(response => response)
    .then(responseJson => {
      return responseJson;
    })
    .catch(error => {
      if (error.code === 'ECONNABORTED' && error.message.includes('timeout')) {
        // Handle timeout error
        console.log('Timeout error: Request took too long to complete');
        return {
          error: "Mengambil data terlalu lama. Silakan coba lagi.",
          code: 408 // HTTP status code untuk timeout
        };
      }
      return {
        error: (error.response && error.response.data.message) || error,
        errorList:
          error.response && error.response.data && error.response.data.errors
      };
    });
};

export const ApiPutRequest = (url, data = {}) => {
  return instance
    .put(url, data)
    .then(response => response)
    .then(responseJson => {
      return responseJson;
    })
    .catch(error => {
      if (error.code === 'ECONNABORTED' && error.message.includes('timeout')) {
        // Handle timeout error
        console.log('Timeout error: Request took too long to complete');
        return {
          error: "Mengambil data terlalu lama. Silakan coba lagi.",
          code: 408 // HTTP status code untuk timeout
        };
      }
      return {
        error: (error.response && error.response.data.message) || error,
        errorList:
          error.response && error.response.data && error.response.data.errors
      };
    });
};

export const ApiDeleteRequest = (url, data = {}) => {
  return instance
    .delete(url, data)
    .then(response => response)
    .then(responseJson => {
      return responseJson;
    })
    .catch(error => {
      if (error.code === 'ECONNABORTED' && error.message.includes('timeout')) {
        // Handle timeout error
        console.log('Timeout error: Request took too long to complete');
        return {
          error: "Mengambil data terlalu lama. Silakan coba lagi.",
          code: 408 // HTTP status code untuk timeout
        };
      }
      return {
        error: (error.response && error.response.data.message) || error,
        errorList:
          error.response && error.response.data && error.response.data.errors
      };
    });
};

export default {
  fetch: instance,
  fetchMultipart: instanceMultipart,
  fetchRkas: instanceRkas,
  cancel,
  setToken
};
