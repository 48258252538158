import moment from "moment";

export const formDataDefault = {
  id: null,
  uuid: null,
  agenda_number: null,
  date: moment().format("DD-MM-YYYY HH:mm:ss"),
  purpose: null,
  subject: null,
  officer: null,
  department: null,
  creator: {},
  updated_at: null,
  created_at: null
};

export const isKacabdin = () => {
  return true;
};

export const truncateStringAgendaSurat = (value, key) => {
  const max = {
    purpose: 100,
    subject: 240
  };
  if (!value) {
    return "";
  }
  return value.length > max[key] ? value.substring(0, max[key]) : value;
};
