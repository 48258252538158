// InternalImplement.js
import moment from "moment";

moment.locale("id");

const ENDPOINT = "";

export default class InternalImplement {
  constructor(apiService) {
    this.apiService = apiService;
  }

  /**
   * mengambil data sekolah
   * */
  async schools(data) {
    try {
      const params = this.prepareParamsSchools(data);
      if (!params) throw new Error("Invalid parameters for schools.");

      return await this.apiService.get(`${ENDPOINT}/schools`, { params });
    } catch (error) {
      console.error("Error in InternalImplement.schools:", error);
      throw error;
    }
  }

  /**
   * mengambil data naungan sekolah sesuai yang login pada provinsi/government
   * ROLE: Gov sd,smp dst
   * */
  async currentSchools(data) {
    try {
      const params = this.prepareParamsCurrentSchools(data);
      if (!params) throw new Error("Invalid parameters for currentSchools.");

      return await this.apiService.get(`${ENDPOINT}/current/schools`, { params });
    } catch (error) {
      console.error("Error in InternalImplement.currentSchools:", error);
      throw error;
    }
  }

  /**
   * mengambil data poin, berdasarkn login di provinsi/gov
   * ROLE: Gov sd,smp dst
   * */
  async current() {
    try {
      return await this.apiService.get(`${ENDPOINT}/current`, { params: {} });
    } catch (error) {
      console.error("Error in InternalImplement.current:", error);
      throw error;
    }
  }

  /**
   * proses perpindahan dari pengawas lama ke pengawas baru
   * */
  async schoolMoveNaunganToEmploye(uuidSchool, formData) {
    try {
      if (typeof formData !== "object" || !formData.source_id || !formData.destination_id) {
        throw new Error(
          "formData must Object {source_id: required, destination_id: required} for schoolMoveNaunganToEmploye."
        );
      }
      if (!uuidSchool) {
        throw new Error(`Invalid uuidSchool: ${uuidSchool} for schoolMoveNaunganToEmploye.`);
      }

      return await this.apiService.post(
        `${ENDPOINT}/schools/${uuidSchool}/re-assign`,
        formData
      );
    } catch (error) {
      console.error(
        "Error in InternalImplement.schoolMoveNaunganToEmploye:",
        error
      );
      throw error;
    }
  }

  async tukarPoin(formData) {
    try {
      if (typeof formData !== "object" || !formData.phone || !formData.points) {
        throw new Error(
          "formData must Object {phone: required, points: required} for tukarPoin."
        );
      }
      const data = {}
      data.phone = formData.phone;
      data.points = formData.points;

      return await this.apiService.post(
        `${ENDPOINT}/points`,
        data
      );
    } catch (error) {
      console.error(
        "Error in InternalImplement.tukarPoin:",
        error
      );
      throw error;
    }
  }

  async batalTukarPoin(pointId) {
    try {
      if (!pointId) {
        throw new Error("pointId isRequired");
      }

      return await this.apiService.delete(`${ENDPOINT}/points/${pointId}`);
    } catch (error) {
      console.error("Error in InternalImplement.batalTukarPoin:", error);
      throw error;
    }
  }

  prepareParamsCurrentSchools(data) {
    const params = {};
    if (data.q) params.q = data.q;
    return params;
  }

  prepareParamsSchools(data) {
    const params = {};
    this.validatePage(data, params);
    this.validateTypeArray(data, params);
    this.validateLimit(data, params);
    this.validateSortField(data, params, [
      "name",
      "type",
      "type_school",
      "created_at"
    ]);
    this.validateSortDir(data, params);

    if (data.q) {
      params.q = data.q; // ops | nama pengawas
    }

    if (data.unsupervised) {
      params.unsupervised = data.unsupervised; // ops | 1
    }

    if (data.city_id) {
      params.city_id = data.city_id; // ops | uuid
    }
    return params;
  }

  validatePage({ page }, params) {
    const format = Number(page);
    if (!format) {
      throw new Error("Page must Integer");
    }
    params.page = format;
  }

  validateTypeArray({ type }, params) {
    if (type) {
      const validTypes = ["sd", "smp", "sma", "smk", "slb"];

      // Memastikan bahwa 'type' adalah sebuah array dan tidak kosong
      if (Array.isArray(type) && type.length > 0) {
        // Validasi setiap elemen dalam array
        type.forEach(t => {
          if (!validTypes.includes(t.toLowerCase())) {
            throw new Error(
              `Invalid type value: '${t}'. Valid values are ${validTypes.join(
                ", "
              )}.`
            );
          }
        });

        // Jika semua elemen valid, tetapkan ke params dalam format huruf besar
        params.type = type.map(t => t.toUpperCase());
      } else {
        // Jika 'type' bukan array atau kosong, lemparkan kesalahan
        throw new Error("Type must be a non-empty array.");
      }
    }
  }

  validateType({ type }, params) {
    const validTypes = ["sd", "smp", "sma", "smk", "slb"];
    if (type && !validTypes.includes(type.toLowerCase())) {
      throw new Error(
        `Invalid type value: '${type}'. Valid values are ${validTypes.join(
          ", "
        )}.`
      );
    } else if (type) {
      params.type = type.toUpperCase();
    }
  }

  validateLimit({ limit }, params) {
    const maxLimit = 500;
    if (limit) {
      const numericLimit = parseInt(limit, 10);
      if (isNaN(numericLimit) || numericLimit > maxLimit) {
        throw new Error(
          `Invalid limit value: '${limit}'. The limit must be a number and cannot exceed ${maxLimit}.`
        );
      }
      params.limit = numericLimit;
    }
  }

  validateSortField({ sort_field }, params, array) {
    const validSortFields = array;
    if (sort_field && !validSortFields.includes(sort_field)) {
      throw new Error(
        `Invalid sort_field value: '${sort_field}'. Valid values are ${validSortFields.join(
          ", "
        )}.`
      );
    }
    params.sort_field = sort_field;
  }

  validateSortDir({ sort_dir }, params) {
    const validSortDirections = ["asc", "desc"];
    if (sort_dir && !validSortDirections.includes(sort_dir)) {
      throw new Error(
        `Invalid sort_dir value: '${sort_dir}'. Valid values are ${validSortDirections.join(
          ", "
        )}.`
      );
    }
    params.sort_dir = sort_dir;
  }
}
