export const formDataDefault = {
  "province_id": null,
  "nip": null,
  "name": null,
  "email": null,
  "phone": null,
  "password": "diskola2024",
  "city_id": null,
  "area_coverage": []
}
