import { ApiGetRequest } from "@/utils/broker";
import { Message } from "element-ui";
import moment from "moment";

const state = {
  data: {
    loading: false,
    tabActive: "PELAJAR",
    start_date: "",
    end_date: "",
    school_id: "",
    sort_dir: "desc",
    summary_pelajar: [],
    items_all_school: [],
    public_transportation: null,
    checklistFilter: false,
    page: 1,
  },
};

const mutations = {
  changeProkesRekamJejak(state, payload) {
    state.data = Object.assign({}, state.data, payload);
  },
};

const actions = {
  async getSummaryProkesRekamJejak({ commit, state }) {
    commit("changeProkesRekamJejak", {
      loading: true,
    });
    const { data } = state;

    let params = {
      sort_dir: data.sort_dir,
    };

    if (data.start_date && data.start_date != "") {
      params["start_date"] = moment(data.start_date).format("YYYY-MM-DD");
    }

    if (data.end_date && data.end_date != "") {
      params["end_date"] = moment(data.end_date).format("YYYY-MM-DD");
    }

    if (data.school_id && data.school_id != "") {
      params["school_id"] = data.school_id;
    }

    const result = await ApiGetRequest(
      `health-protocol/student/track-record/summary`,
      params
    );

    if (result.error) {
      Message({
        type: "error",
        message: result.error,
      });
    } else {
      commit("changeProkesRekamJejak", {
        summary_pelajar: result.data.data,
        loading: false,
      });
    }
  },

  async getAllSchool({ commit }) {
    commit("changeProkesRekamJejak", {
      loading: true,
    });

    const result = await ApiGetRequest(`health-protocol/school/list`);

    if (result.error) {
      Message({
        type: "error",
        message: result.error,
      });
    } else {
      commit("changeProkesRekamJejak", {
        items_all_school: result.data.data,
        loading: false,
      });
    }
  },

  async getChartProkesRekamJejak({ commit, state }) {
    commit("changeProkesRekamJejak", {
      loading: true,
    });

    const { data } = state;

    let params = {};

    if (data.start_date && data.start_date != "") {
      params["start_date"] = moment(data.start_date).format("YYYY-MM-DD");
    }

    if (data.end_date && data.end_date != "") {
      params["end_date"] = moment(data.end_date).format("YYYY-MM-DD");
    }

    if (data.school_id && data.school_id != "") {
      params["school_id"] = data.school_id;
    }

    const result = await ApiGetRequest(
      `health-protocol/student/track-record/percentage`,
      params
    );

    if (result.error) {
      Message({
        type: "error",
        message: result.error,
      });
    } else {
      commit("changeProkesRekamJejak", {
        public_transportation: result.data.data.public_transportation,
        loading: false,
      });
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
