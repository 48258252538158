import { ApiGetRequest } from '@/utils/broker';
import { Message } from 'element-ui';
import moment from 'moment';

const state = {
  data: {
    cities: [],
    page: 1,
    limit: 10,
    total: 0,
    from: 0,
    q: '',
    items_prokes_pelajar: [],
    filter_jenjang: '',
    filter_tgl: moment().format('YYYY-MM-DD'),
    detail: false,
  },
};

const mutations = {
  changeProkesPelajar(state, payload) {
    state.data = Object.assign({}, state.data, payload);
  },
};

const actions = {
  async getProkesPelajar({ commit, state }) {
    const { data } = state;
    let params = {
      page: data.page,
      limit: data.limit,
      q: data.q,
    };

    if (data.filter_tgl && data.filter_tgl !== '') {
      params.date = moment(data.filter_tgl).format('YYYY-MM-DD');
    }

    if (data.filter_jenjang && data.filter_jenjang !== '') {
      params.grade = data.filter_jenjang;
    }

    const result = await ApiGetRequest(
      `health-protocol/student/daily-detection`,
      params
    );
    if (result.error) {
      Message({
        type: 'error',
        message: result.error,
      });
    } else {
      commit('changeProkesPelajar', {
        items_prokes_pelajar: result.data.data,
        total: result.data.meta
          ? result.data.meta.total
          : result.data.data.length,
        from: result.data.meta ? result.data.meta.from : 0,
      });

      return result.data.data;
    }
  },

  async getDetailProkesPelajar({ commit }, payload = null) {
    const result = await ApiGetRequest(
      `health-protocol/student/daily-detection/${
        payload.id
      }/report-information?date=${
        payload.date && payload.date !== ''
          ? moment(payload.date).format('YYYY-MM-DD')
          : ''
      }`
    );
    if (result.error) {
      Message({
        type: 'error',
        message: result.error,
      });
    } else {
      commit('changeProkesPelajar', {
        detail: result.data.data,
      });

      return result.data.data;
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
