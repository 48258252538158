import axios from "axios";

var token = window.localStorage.getItem("token");

const ApiService = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  timeout: 20000,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  },
});


ApiService.interceptors.request.use(
  (config) => {
    config.headers = {
      ...config.headers,
      Authorization: `Bearer ${token}`,
    };
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

ApiService.interceptors.response.use(response => {
  return response;
}, error => {
  if (error.response) {
    if (error.response.status === 401) {
      //localStorage.clear();
      //window.location = "/";
      return Promise.reject({
        code: 401,
        errorList: [],
        error: "Silahkan Login Ulang.",
      });
    }
    const response = {
      code: error.response ? error.response.status : 500,
      error:
        (error.response && error.response.data.error
          ? error.response.data.error
          : error.response.data.message) || error,
      errorList:
        error.response && error.response.data && error.response.data.errors,
    };
    if (error.response.status === 307) {
      response.data = error.response.data;
    }
    return Promise.reject(response);
  } else if (error.request) {
    console.log("error request");
    return Promise.reject(error);
  }

  return Promise.reject(error);
});

export default ApiService;
