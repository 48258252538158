import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import imageDefault from "@/assets/image/logo/logo-app.png";

Vue.use(Vuex);

// set supaya akm gak masuk persist state, gak dimasukin persist karna jsonya besar,
// dan local storage ada limitnya
const dataState = createPersistedState({
  paths: [
    "user",
    "scope",
    "token",
    "isDefaultPass",
    "school",
    "periods",
    "selectedPeriod",
  ],
});

import ProkesRekamJejak from "./modules/ProkesRekamJejak";
import ProkesDashboard from "./modules/ProkesDashboard";
import ProkesLaporanData from "./modules/ProkesLaporanData";
import ProkesPelajar from "./modules/ProkesPelajar";
import ProkesPartisipasi from "./modules/ProkesPartisipasi";
import ProkesSatgasSekolah from "./modules/ProkesSatgasSekolah";
import ConfigApp from "./store/config/index";
import UnitDepartment from "./modules/UnitDepartment/index";
import Districts from "./modules/Districts";
import Cities from "./modules/Cities";
import Employee from "./modules/Employee";
import GenerateToken from "./modules/GenerateToken";
import AgendaSurat from "./modules/AgendaSurat";
import {ROLES_ADMIN} from "./services/employeeService";
import ManajemenPengawas from "@/modules/ManajemenPengawas";
import Schools from "@/modules/Schools/index";
import PengawasNaungan from "@/modules/PengawasNaungan/index";
import PengawasPoin from "@/modules/PengawasPoin/index";
import PPRiwayat from "@/modules/PengawasPoin/Riwayat/index";
import PPTukarPoin from "@/modules/PengawasPoin/TukarPoin/index";

export const store = new Vuex.Store({
  modules: {
    configApp: ConfigApp,
    prokesRekamJejak: ProkesRekamJejak,
    prokesDashboard: ProkesDashboard,
    prokesLaporanData: ProkesLaporanData,
    prokesPelajar: ProkesPelajar,
    prokesPartisipasi: ProkesPartisipasi,
    prokesSatgasSekolah: ProkesSatgasSekolah,
    stateUnitDepartment: UnitDepartment,
    employee: Employee,
    districts: Districts,
    cities: Cities,
    generateToken: GenerateToken,
    agendaSurat: AgendaSurat,
    manajemenPengawas: ManajemenPengawas,
    schools: Schools,
    pengawasNaungan: PengawasNaungan,
    pengawasPoin: PengawasPoin,
    riwayatPP: PPRiwayat,
    tukarPoinPP: PPTukarPoin,
  },
  state: {
    user: {},
    scope: {},
    token: "",
    isDefaultPass: false,
    school: {},
    periods: [],
    selectedPeriod: null,
    akm: {
      level: 1,
      date: "",
      time: {},
      grade: "10",
      school_types: [],
      name: "",
      school_cities: [],
      template: [],
      schedules: [],
      page: 1,
      limit: 10,
      total: 0,
      type_soal: "",
      loading: false,
      stopRequest: false,
      searchParams: "",
      filterGrade: "",
      formNumerasi: {
        questionType: 1,
        choices: [],
      },
      formFiksi: {
        questionType: 1,
        choices: [],
      },
      formInformasi: {
        questionType: 1,
        choices: [],
      },
    },
  },
  mutations: {
    updateState(state, payload) {
      Object.keys(payload).map((item) => {
        state[item] = payload[item];
      });
    },
    updateUser: (state, payload) => {
      state.user = payload;
    },
    updateToken: (state, payload) => {
      state.token = payload;
    },
    updatePeriods: (state, payload) => {
      if (Array.isArray(payload) && payload.length > 0) {
        state.selectedPeriod = new Date().getFullYear();
      }
      state.periods = payload;
    },
    updateScope: (state, payload) => {
      state.scope = payload;
    },
    updateAkm: (state, payload) => {
      state.akm = Object.assign({}, state.akm, payload);
    },
  },
  actions: {
    updateUser: (context, payload) => {
      context.commit("updateUser", payload);
    },
    updateToken: (context, payload) => {
      context.commit("updateToken", payload);
    },
    updatePeriods: (context, payload) => {
      context.commit("updatePeriods", payload);
    },
    updateScope: (context, payload) => {
      context.commit("updateScope", payload);
    },
  },
  getters: {
    userNameGetters: state => {
      try {
        return state.user.name || "Silahkan Login";
      } catch (e) {
        return "Silahkan Login";
      }
    },
    userEmailGetters: state => {
      try {
        return state.user.email || "Silahkan Login";
      } catch (e) {
        return "Silahkan Login";
      }
    },
    userPhoneGetters: state => {
      try {
        return state.user.phone || "Silahkan Login";
      } catch (e) {
        return "Silahkan Login";
      }
    },
    govermentIdGetters: state => {
      try {
        return state.user.government.id;
      } catch (e) {
        return 0;
      }
    },
    govermentImageGetters: state => {
      try {
        return state.user.government.image || imageDefault;
      } catch (e) {
        return imageDefault;
      }
    },
    provinceIdGetters: state => {
      try {
        return state.user.government.province.id;
      } catch (e) {
        return 0;
      }
    },
    provinceNameGetters: state => {
      try {
        return state.user.government.province.name || "tidak ditemukan";
      } catch (e) {
        return "tidak ditemukan";
      }
    },
    scopeNameGetters: state => {
      try {
        return state.scope.name || "tidak ditemukan";
      } catch (e) {
        return "tidak ditemukan";
      }
    },

    /**
     * return array
     * */
    rolesArrayGetters: state => {
      try {
        return state.user.roles.map(role => role.name);
      } catch (e) {
        return [];
      }
    },

    /**
     * return {Boolean}
     * */
    isRolesAdminGovermentGetters: (state, getters) => {
      try {
        const userRoles = getters.rolesArrayGetters;
        return ROLES_ADMIN.some(adminRole => userRoles.includes(adminRole));
      } catch (e) {
        return false;
      }
    },

    /**
     * return string
     * */
    cabangAdminGetters: state => {
      const message = "Tidak ditemukan";
      try {
        return state.user?.city?.name || message;
      } catch (e) {
        return message;
      }
    },

    /**
     * return string
     * */
    cityUuidAdminGetters: state => {
      const message = "0";
      try {
        return state.user?.city?.id || message;
      } catch (e) {
        return message;
      }
    },
  },
  plugins: [dataState],
});
