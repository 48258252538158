import { ApiGetRequest } from '@/utils/broker';
import { Message } from 'element-ui';
import moment from 'moment';

const state = {
  data: {
    fillPelajar: {
      gradient: ['#FF2E00', '#FF2E00', '#FF2E00'],
      color: '#FF2E00',
    },
    fillOrtu: {
      gradient: ['#0054FE', '#0054FE', '#0054FE'],
      color: '#0054FE',
    },
    fillGuru: {
      gradient: ['#34B53A', '#34B53A', '#34B53A'],
      color: '#34B53A',
    },
    options: {
      chart: {
        type: 'column',
        height: 250,
      },
      legend: {
        enabled: false,
      },
      title: {
        text: '',
      },
      subtitle: {
        text: '',
      },
      xAxis: {
        categories: ['Ya', 'Tidak'],
        crosshair: true,
      },
      yAxis: {
        min: 0,
        title: {
          text: '',
        },
      },
      tooltip: {
        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        pointFormat: '<td style="padding:0"><b>{point.y:.1f} %</b></td></tr>',
        footerFormat: '</table>',
        shared: true,
        useHTML: true,
      },
      plotOptions: {
        column: {
          pointPadding: 0.2,
          borderWidth: 0,
        },
        series: {
          dataLabels: {
            enabled: true,
          },
        },
      },
      series: [
        {
          name: 'Data',
          colorByPoint: true,
          dataLabels: [
            {
              align: 'top',
              format: '{y} %',
            },
          ],
          data: [
            {
              name: 'Ya',
              y: 47,
              color: '#F65656',
              dataLabels: {
                color: '#F65656',
                style: {
                  fontSize: '12px',
                },
              },
            },
            {
              name: 'Tidak',
              y: 53,
              color: '#4885FF',
              dataLabels: {
                color: '#4885FF',
                style: {
                  fontSize: '12px',
                },
              },
            },
          ],
        },
      ],
    },
    procentaseGuru: 0,
    chartMonthlyGuru: {},
    totalGuru: 0,
    peringkatGuru: [],
    procentaseStudent: 0,
    chartMonthlyStudent: {},
    totalStudent: 0,
    peringkatStudent: [],
    procentaseOrtu: 0,
    chartMonthlyOrtu: {},
    totalOrtu: 0,
    peringkatOrtu: [],
    loading: false,
    list_school: [],
    select_school: '',
    start_date: '',
    end_date: '',
    sort_peringkat: '',
  },
};

const mutations = {
  changeDashboard(state, payload) {
    state.data = Object.assign({}, state.data, payload);
  },
};

const actions = {
  async getProcentaseGuru({ commit }) {
    const result = await ApiGetRequest(
      `health-protocol/dashboardpercentage/teacher`
    );
    if (result.error) {
      Message({
        type: 'error',
        message: result.error,
      });
    } else {
      commit('changeDashboard', {
        procentaseGuru: {
          ...result.data.data,
          vaccinated: +result.data.data.vaccinated.replace('%', ''),
        },
      });

      return result.data.data;
    }
  },
  async getChartMonthlyGuru({ commit, state }) {
    const { data } = state;
    let params = {
      school_id: data.select_school ? data.select_school : '',
    };

    if (!params.school_id || params.school_id === '') {
      delete params.school_id;
    }

    const result = await ApiGetRequest(
      `health-protocol/teacher/vaccination/list`,
      params
    );
    if (result.error) {
      Message({
        type: 'error',
        message: result.error,
      });

      commit('changeDashboard', {
        loading: false,
      });

      return false;
    } else {
      commit('changeDashboard', {
        chartMonthlyGuru: result.data.data,
        loading: false,
      });

      return result.data.data;
    }
  },
  async getTotalGuru({ commit }) {
    const result = await ApiGetRequest(`health-protocol/teacher/total`);
    if (result.error) {
      Message({
        type: 'error',
        message: result.error,
      });
    } else {
      commit('changeDashboard', {
        totalGuru: result.data.data,
      });

      return result.data.data;
    }
  },
  async getPeringkatGuru({ commit, state }) {
    const { data } = state;
    let params = {};
    if (
      data.start_date &&
      data.start_date !== '' &&
      data.end_date &&
      data.end_date !== ''
    ) {
      params.start_date = moment(data.start_date).format('YYYY-MM-DD');
      params.end_date = moment(data.end_date).format('YYYY-MM-DD');
    }

    if (data.sort_peringkat && data.sort_peringkat !== '') {
      params.sort_dir = data.sort_peringkat;
    }

    const result = await ApiGetRequest(
      `health-protocol/teacher/vaccination/school-ranking`,
      params
    );
    if (result.error) {
      Message({
        type: 'error',
        message: result.error,
      });
    } else {
      commit('changeDashboard', {
        peringkatGuru: result.data.data,
      });

      return result.data.data;
    }
  },
  async getProcentaseStudent({ commit }) {
    const result = await ApiGetRequest(
      `health-protocol/dashboardpercentage/student`
    );
    if (result.error) {
      Message({
        type: 'error',
        message: result.error,
      });
    } else {
      commit('changeDashboard', {
        procentaseStudent: {
          ...result.data.data,
          vaccinated: +result.data.data.vaccinated.replace('%', ''),
        },
      });

      return result.data.data;
    }
  },
  async getChartMonthlyStudent({ commit, state }) {
    const { data } = state;
    let params = {
      school_id: data.select_school ? data.select_school : '',
    };

    if (!params.school_id || params.school_id === '') {
      delete params.school_id;
    }

    const result = await ApiGetRequest(
      `health-protocol/student/vaccination/list`,
      params
    );
    if (result.error) {
      Message({
        type: 'error',
        message: result.error,
      });

      commit('changeDashboard', {
        loading: false,
      });

      return false;
    } else {
      commit('changeDashboard', {
        chartMonthlyStudent: result.data.data,
        loading: false,
      });

      return result.data.data;
    }
  },
  async getTotalStudent({ commit }) {
    const result = await ApiGetRequest(`health-protocol/student/total`);
    if (result.error) {
      Message({
        type: 'error',
        message: result.error,
      });
    } else {
      commit('changeDashboard', {
        totalStudent: result.data.data,
      });

      return result.data.data;
    }
  },
  async getPeringkatStudent({ commit, state }) {
    const { data } = state;
    let params = {};
    if (
      data.start_date &&
      data.start_date !== '' &&
      data.end_date &&
      data.end_date !== ''
    ) {
      params.start_date = moment(data.start_date).format('YYYY-MM-DD');
      params.end_date = moment(data.end_date).format('YYYY-MM-DD');
    }

    if (data.sort_peringkat && data.sort_peringkat !== '') {
      params.sort_dir = data.sort_peringkat;
    }

    const result = await ApiGetRequest(
      `health-protocol/student/vaccination/school-ranking`,
      params
    );
    if (result.error) {
      Message({
        type: 'error',
        message: result.error,
      });
    } else {
      commit('changeDashboard', {
        peringkatStudent: result.data.data,
      });

      return result.data.data;
    }
  },
  async getProcentaseOrtu({ commit }) {
    const result = await ApiGetRequest(
      `health-protocol/dashboardpercentage/ortu-wali`
    );
    if (result.error) {
      Message({
        type: 'error',
        message: result.error,
      });
    } else {
      commit('changeDashboard', {
        procentaseOrtu: {
          ...result.data.data,
          vaccinated: +result.data.data.vaccinated.replace('%', ''),
        },
      });

      return result.data.data;
    }
  },
  async getChartMonthlyOrtu({ commit, state }) {
    const { data } = state;
    let params = {
      school_id: data.select_school ? data.select_school : '',
    };

    if (!params.school_id || params.school_id === '') {
      delete params.school_id;
    }

    const result = await ApiGetRequest(
      `health-protocol/parent/vaccination/list`,
      params
    );
    if (result.error) {
      Message({
        type: 'error',
        message: result.error,
      });

      commit('changeDashboard', {
        loading: false,
      });

      return false;
    } else {
      commit('changeDashboard', {
        chartMonthlyOrtu: result.data.data,
        loading: false,
      });

      return result.data.data;
    }
  },
  async getTotalOrtu({ commit }) {
    const result = await ApiGetRequest(`health-protocol/parent/total`);
    if (result.error) {
      Message({
        type: 'error',
        message: result.error,
      });
    } else {
      commit('changeDashboard', {
        totalStudent: result.data.data,
      });

      return result.data.data;
    }
  },
  async getPeringkatOrtu({ commit }) {
    const result = await ApiGetRequest(
      `health-protocol/parent/vaccination/school-ranking`
    );
    if (result.error) {
      Message({
        type: 'error',
        message: result.error,
      });
    } else {
      commit('changeDashboard', {
        peringkatOrtu: result.data.data,
      });

      return result.data.data;
    }
  },
  async searchSchool({ commit }, payload = null) {
    let params = {
      limit: 15,
      q: payload ? payload : '',
    };

    const result = await ApiGetRequest(`health-protocol/school/list`, params);
    if (result.error) {
      Message({
        type: 'error',
        message: result.error,
      });
    } else {
      const dataObj = result.data.data.map((item) => {
        item['value'] = item.name;
        return item;
      });

      commit('changeDashboard', {
        list_school: dataObj || [],
      });

      return result.data.data;
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
