import ApiService from "@/utils/ApiService";
import InternalImplement from "@/api/InternalImplement";

const state = {
  data: {
    loading: 0,
    detailCurrent: {
      id: 87,
      nip: "2328342374",
      name: "zuz sd",
      email: "zuzubaidillah21312@gmail.com",
      phone: "32742834823",
      roles: ["Gov SMA"],
      department: {},
      city: null,
      points: null
    }
  }
};

const mutations = {
  changePengawasPoin(state, payload) {
    state.data = Object.assign({}, state.data, payload);
  },
  changeLoading(state, payload) {
    state.data.loading = payload;
  },
};

const actions = {

  async getPengawasPoin({ commit }) {

    try {
      commit("changeLoading", true);
      const internalService = new InternalImplement(ApiService);
      const result = await internalService.current();
      commit("changeLoading", false);

      commit("changePengawasPoin", {
        detailCurrent: result?.data?.data || [],
      });
      return true;
    } catch (error) {
      if (!error || error.error) {
        commit("changePengawasPoin", {
          detailCurrent: {},
        });
        return false;
      }
    }
  },

};

const getters = {
  totalPointsGetters: state => {
    const { points } = state.data.detailCurrent;
    if (!points) {
      return 0;
    }
    if (typeof points === "object") {
      return points.points;
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
