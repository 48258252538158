import ApiService from "@/utils/ApiService";
import moment from "moment/moment";
import Swal from "sweetalert2";
import TransactionImplement from "@/api/TransactionImplement";
import InternalImplement from "@/api/InternalImplement";

const state = {
  data: {
    sort_field: "name",
    sort_dir: false,
    filterDate: [],
    filter: "",
    filterType: "",
    items: [],
    itemsAll: [],
    limit: 10,
    page: 1,
    lastPage: 1,
    total: 0,
    errors: [],
    loading: false,
    showmodal: false,
    typeAction: "read",
    listLimit: [10, 25, 50, 100],
    formData: {
      phone: null,
      points: null,
      name: null
    }
  }
};

const mutations = {
  changeTukarPoinPP(state, payload) {
    state.data = Object.assign({}, state.data, payload);
  },
  changeLoading(state, payload) {
    state.data.loading = payload;
  },
  DELETE_INDEX_ITEMS(state, index) {
    state.data.items.splice(index, 1)
  }
};

const actions = {
  async getTukarPoinPP({ commit, state }, payload) {
    const { lastPage, limit, page, filterDate } = state.data;

    let payloadPage = payload?.page || page;
    if (payloadPage > lastPage && payload?.page) {
      console.log("STOP", payloadPage, lastPage);
      return false;
    }

    const date = filterDate ? filterDate : [];

    if (date.length === 2) {
      const startDate = moment(date[0]);
      const endDate = moment(date[1]);
      const dayDiff = endDate.diff(startDate, "days");

      if (dayDiff > 31) {
        Swal.fire("Informasi", "Range Tanggal Maksimal maksimal 31Hari");
        return false;
      }
    }
    const params = {
      start_date: date[0],
      end_date: date[1],
      limit: limit,
      type: "OUT",
      page: payloadPage
    };

    try {
      commit("changeLoading", true);
      const transactionService = new TransactionImplement(ApiService);
      const result = await transactionService.history(params);
      commit("changeLoading", false);

      commit("changeTukarPoinPP", {
        items: result?.data?.data || [],
        total: result?.data?.meta?.total || result?.data?.data.length,
        page: result?.data?.meta?.current_page || 1,
        lastPage: result?.data?.meta?.last_page || 1
      });
      return true;
    } catch (error) {
      commit("changeLoading", false);
      commit("changeTukarPoinPP", {
        items: [],
        total: 0,
        page: 1,
        lastPage: 1
      });
      return false;
    }
  },

  async postTukarPoinPP({ commit, state, dispatch }) {
    const { formData } = state.data;
    try {
      commit("changeLoading", true);
      const internalService = new InternalImplement(ApiService);
      await internalService.tukarPoin(formData);
      commit("changeLoading", false);
      Swal.fire("Berhasil", "Proses pengajuan Berhasil", "success");
      dispatch("getTukarPoinPP");
      return true;
    } catch (error) {
      commit("changeLoading", false);
      Swal.fire(
        "Gagal",
        `Proses pengajuan gagal dilakukan. <br>${error.error}`,
        "error"
      );
      return false;
    }
  },

  async deleteTukarPoinPP({ commit }, payload) {
    const {pointId, index} = payload;
    try {
      commit("changeLoading", true);
      const internalService = new InternalImplement(ApiService);
      await internalService.batalTukarPoin(pointId);
      commit("changeLoading", false);
      commit("DELETE_INDEX_ITEMS", index);
      Swal.fire("Berhasil", "Pembatalan pengajuan Berhasil", "success");
      return true;
    } catch (error) {
      commit("changeLoading", false);
      Swal.fire(
        "Gagal",
        `Proses pengajuan gagal dilakukan. <br>${error.error}`,
        "error"
      );
      return false;
    }
  }
};

const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
