import { ROLES_PETUGAS } from "@/services/employeeService";
import ApiService from "@/utils/ApiService";
import InternalImplement from "@/api/InternalImplement";
import Swal from "sweetalert2";

const state = {
  data: {
    filterNotPengawas: "",
    filterType: "",
    filterCity: "",
    sort_field: "name",
    sort_dir: false,
    filter: "",
    items: [],
    itemsAll: [],
    selects: [],
    limit: 10,
    page: 1,
    lastPage: 1,
    total: 0,
    errors: [],
    loading: false,
    listLimit: [10, 25, 50, 100],
    filterChildFrom: "internal",
    itemsType: ["SD", "SMP", "SMA", "SMK", "SLB"]
  }
};

const mutations = {
  changeSchools(state, payload) {
    state.data = Object.assign({}, state.data, payload);
  },
  changeLoading(state, payload) {
    state.data.loading = payload;
  },
  changeFilterChildFrom(state, payload) {
    state.data.filterChildFrom = payload;
  }
};

const actions = {
  /**
   * payload: {
   *   hideMessage: optional
   * }
   * */
  async getSchools({ commit, state, getters }, payload = {}) {
    const { hideMessage } = payload;
    const {
      filter,
      filterCity,
      filterNotPengawas,
      filterType,
      lastPage,
      limit,
      sort_dir,
      sort_field,
      page,
      filterChildFrom
    } = state.data;

    let payloadPage = payload?.page || page;
    if (payloadPage > lastPage && payload?.page) {
      console.log("STOP", payloadPage, lastPage);
      return false;
    }

    const params = {
      limit: limit,
      sort_field: "name",
      sort_dir: false,
      q: filter,
      roles: ROLES_PETUGAS,
      page: payloadPage
    };

    if (sort_field) {
      params.sort_field = sort_field;
      params.sort_dir = sort_dir ? "desc" : "asc";
    }

    if (filterType) {
      params.type = [filterType];
    } else {
      if (filterChildFrom === "detailPengawas") {
        params.type = getters.arrayTypeSchoolGetters;
      }
    }

    if (filterCity) {
      params.city_id = filterCity;
    }

    if (filterNotPengawas) {
      params.unsupervised = 1;
    }

    try {
      commit("changeLoading", true);
      const internalService = new InternalImplement(ApiService);
      const result = await internalService.schools(params);
      commit("changeLoading", false);

      commit("changeSchools", {
        items: result?.data?.data || [],
        total: result?.data?.meta?.total || result?.data?.data.length,
        page: result?.data?.meta?.current_page || 1,
        lastPage: result?.data?.meta?.last_page || 1
      });
      return true;
    } catch (error) {
      commit("changeSchools", {
        items: [],
        total: 0,
        page: 1,
        lastPage: 1
      });
      if (!hideMessage) {
        Swal.fire(
          "Gagal",
          `Proses mengambil data gagal. <br><br>${error.error}`,
          "error"
        );
        return false;
      }
      return false;
    }
  },
  /**
   * payload: {
   *   hideMessage: optional
   * }
   * */
  async getSchoolsAllByCity({ commit, state }, payload = {}) {
    const { hideMessage } = payload;
    const { filterCity, sort_dir, sort_field } = state.data;

    if (!filterCity) {
      console.error("getSchoolsAllByCity: city isRequired");
      return false;
    }

    const params = {
      page: 1,
      limit: 0,
      sort_field: "name",
      sort_dir: false
    };

    if (sort_field) {
      params.sort_field = sort_field;
      params.sort_dir = sort_dir ? "desc" : "asc";
    }
    params.type = ["SD", "SMP", "SMA", "SMK", "SLB"];
    params.city_id = filterCity;

    try {
      commit("changeLoading", true);
      const internalService = new InternalImplement(ApiService);
      const result = await internalService.schools(params);
      commit("changeLoading", false);

      commit("changeSchools", {
        itemsAll: result?.data?.data || [],
      });
      return true;
    } catch (error) {
      commit("changeSchools", {
        itemsAll: [],
      });
      console.log("--->error", error);
      if (!hideMessage) {
        Swal.fire(
          "Gagal",
          `Proses mengambil data gagal. <br><br>${error.error}`,
          "error"
        );
        return false;
      }
      return false;
    }
  }
};

const getters = {
  arrayTypeSchoolGetters: (state, getters, rootState) => {
    try {
      const { filterChildFrom, itemsType } = state.data;

      if (filterChildFrom === "internal") {
        return itemsType;
      }

      const { detail } = rootState.manajemenPengawas.data.detailPengawas;

      return itemsType.filter(item =>
        detail.roles.includes("Gov " + item.toUpperCase())
      );
    } catch (e) {
      return [];
    }
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
