import { ROLES } from "@/services/employeeService";

const state = {
  data: {
    items: [],
    selectTypeSchool: [
      {
        roles: [ROLES.sd, ROLES.kacabdin],
        region: "city",
        value: "SD",name: "Gov SD",
        label: "SD"
      },
      {
        roles: [ROLES.smp, ROLES.kacabdin],
        region: "city",
        value: "SMP",name: "Gov SMP",
        label: "SMP"
      },
      {
        roles: [ROLES.sma, ROLES.kadis, ROLES.kacabdin],
        region: "province",
        value: "SMA",name: "Gov SMA",
        label: "SMA"
      },
      {
        roles: [ROLES.smk, ROLES.kadis, ROLES.kacabdin],
        region: "province",
        value: "SMK",name: "Gov SMK",
        label: "SMK"
      },
      {
        roles: [ROLES.slb, ROLES.kadis, ROLES.kacabdin],
        region: "province",
        value: "SLB",name: "Gov SLB",
        label: "SLB"
      }
    ]
  }
};

const mutations = {
  changeEmployee(state, payload) {
    state.data = Object.assign({}, state.data, payload);
  }
};

const actions = {};
const getters = {
  typeSchoolGetters: (state, getters, rootState, rootGetters) => {
    const currentRoles = rootGetters.rolesArrayGetters;

    return state.data.selectTypeSchool.filter(item => {
      return item.roles.some(role => currentRoles.includes(role));
    });
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
