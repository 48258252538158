// InternalImplement.js
import moment from "moment";

moment.locale("id");

const ENDPOINT = "/transaction";

export default class TransactionImplement {
  constructor(apiService) {
    this.apiService = apiService;
  }

  async history(data) {
    try {
      const params = this.prepareParamsHistory(data);
      if (!params) throw new Error("Invalid parameters for history.");

      return await this.apiService.get(`${ENDPOINT}/history`, { params });
    } catch (error) {
      console.error("Error in TransactionImplement.history:", error);
      throw error;
    }
  }

  isValidDateYmd(dateString) {
    const regex = /^\d{4}-\d{2}-\d{2}$/; // Regex for YYYY-MM-DD format
    return regex.test(dateString);
  }

  prepareParamsHistory(data) {
    const params = {};
    if (data.start_date) {
      if (!data.end_date) {
        throw new Error("end_date isRequired Y-m-d.");
      }
      if (!this.isValidDateYmd(data.start_date)) {
        throw new Error("Invalid start_date format. Required format: YYYY-MM-DD.");
      }
      params.start_date = data.start_date;
    }
    if (data.end_date) {
      if (!data.start_date) {
        throw new Error("start_date isRequired Y-m-d.");
      }
      if (!this.isValidDateYmd(data.end_date)) {
        throw new Error("Invalid end_date format. Required format: YYYY-MM-DD.");
      }
      params.end_date = data.end_date;
    }
    if (data.type) {
      params.type = data.type;
    }
    this.validateLimit(data, params);
    this.validatePage(data, params);
    return params;
  }

  validatePage({ page }, params) {
    const format = Number(page);
    if (!format) {
      throw new Error("Page must Integer");
    }
    params.page = format;
  }

  validateLimit({ limit }, params) {
    const maxLimit = 500;
    if (limit) {
      const numericLimit = parseInt(limit, 10);
      if (isNaN(numericLimit) || numericLimit > maxLimit) {
        throw new Error(
          `Invalid limit value: '${limit}'. The limit must be a number and cannot exceed ${maxLimit}.`
        );
      }
      params.limit = numericLimit;
    }
  }

  validateSortField({ sort_field }, params, array) {
    const validSortFields = array;
    if (sort_field && !validSortFields.includes(sort_field)) {
      throw new Error(
        `Invalid sort_field value: '${sort_field}'. Valid values are ${validSortFields.join(
          ", "
        )}.`
      );
    }
    params.sort_field = sort_field;
  }

  validateSortDir({ sort_dir }, params) {
    const validSortDirections = ["asc", "desc"];
    if (sort_dir && !validSortDirections.includes(sort_dir)) {
      throw new Error(
        `Invalid sort_dir value: '${sort_dir}'. Valid values are ${validSortDirections.join(
          ", "
        )}.`
      );
    }
    params.sort_dir = sort_dir;
  }
}
