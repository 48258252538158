<template>
  <base-nav
    class="navbar-top navbar-dark"
    id="navbar-main"
    :show-toggle-button="false"
    expand
  >
    <ul class="navbar-nav align-items-center d-none d-md-flex ml-lg-auto">
      <li class="nav-item dropdown">
        <base-dropdown class="nav-link pr-0">
          <div class="media align-items-center cursor-pointer" slot="title">
            <div class="media-body mr-2 d-none d-lg-block">
              <span class="mb-0 text-sm text-muted">
                <i class="fas fa-bell mr-2"></i> {{ userNameGetters }}
              </span>
            </div>
            <span class="avatar avatar-sm">
              <img
                alt="Image placeholder"
                :src="govermentImageGetters"
              />
            </span>
          </div>

          <template>
            <div class=" dropdown-header noti-title">
              <h6 class="text-overflow m-0">Welcome!</h6>
            </div>
            <!-- <router-link to="/profile" class="dropdown-item">
              <i class="ni ni-single-02"></i>
              <span>My profile</span>
            </router-link>
            <router-link to="/profile" class="dropdown-item">
              <i class="ni ni-settings-gear-65"></i>
              <span>Settings</span>
            </router-link>
            <router-link to="/profile" class="dropdown-item">
              <i class="ni ni-calendar-grid-58"></i>
              <span>Activity</span>
            </router-link>
            <router-link to="/profile" class="dropdown-item">
              <i class="ni ni-support-16"></i>
              <span>Support</span>
            </router-link> -->
            <div class="dropdown-divider"></div>
            <router-link :to="{name: 'profile'}" class="dropdown-item">
              <i class="ni ni-single-02"></i>
              <span>Profile</span>
            </router-link>

            <div class="dropdown-divider"></div>
            <a href="#" @click="doLogout" class="dropdown-item">
              <i class="fas fa-sign-out-alt"></i>
              <span>Logout</span>
            </a>
          </template>
        </base-dropdown>
      </li>
    </ul>
    <modal-profile
      v-if="modalVisible"
      @close="modalVisible = false"
      class="overlay"
    ></modal-profile>
  </base-nav>
</template>
<script>
import ModalProfile from '../../components/Goverment/Modal/Profile/ModalProfile.vue';
import {mapGetters} from "vuex";
export default {
  data() {
    return {
      modalVisible: false,
      activeNotifications: false,
      showMenu: false,
      searchQuery: '',
      dataUser: JSON.parse(localStorage.getItem('dataUser')),
    };
  },

  components: {
    ModalProfile,
  },
  computed: {
    ...mapGetters(["userNameGetters", "govermentImageGetters"]),
  },
  methods: {
    openModal() {
      this.modalVisible = true;
    },
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    hideSidebar() {
      this.$sidebar.displaySidebar(false);
    },
    toggleMenu() {
      this.showMenu = !this.showMenu;
    },
    doLogout() {
      this.$store.dispatch('updateUser', {});
      this.$store.dispatch('updateToken', '');
      localStorage.removeItem('token');
      this.$Toast.fire({
        type: 'success',
        title: 'Session destroy',
      });
      // this.$router.push("/");
      window.location.href = '/';
    },
  },
};
</script>
