import { ApiGetRequest } from '@/utils/broker';
import { Message } from 'element-ui';
import moment from 'moment';

const state = {
  data: {
    cities: [],
    page: 1,
    limit: 10,
    total: 0,
    from: 0,
    q: '',
    items: [],
    filter_jenjang: '',
    filter_tgl: moment().format('YYYY-MM-DD'),
    detail: false,
  },
};

const mutations = {
  changeProkesSatgas(state, payload) {
    state.data = Object.assign({}, state.data, payload);
  },
};

const actions = {
  async getProkesSatgas({ commit, state }) {
    const { data } = state;
    let params = {
      page: data.page,
      limit: data.limit,
      q: data.q,
    };

    if (data.filter_tgl && data.filter_tgl !== '') {
      params.date = moment(data.filter_tgl).format('YYYY-MM-DD');
    }

    if (data.filter_jenjang && data.filter_jenjang !== '') {
      params.grade = data.filter_jenjang;
    }

    const result = await ApiGetRequest(`health-protocol/taskforce`, params);
    if (result.error) {
      Message({
        type: 'error',
        message: result.error,
      });
    } else {
      commit('changeProkesSatgas', {
        items: result.data.data,
        total: result.data.meta
          ? result.data.meta.total
          : result.data.data.length,
        from: result.data.meta ? result.data.meta.from : 0,
      });

      return result.data.data;
    }
  },

  async getDetailProkesSatgas({ commit }, payload = null) {
    const result = await ApiGetRequest(
      `health-protocol/taskforce/${payload.id}/info`
    );
    if (result.error) {
      Message({
        type: 'error',
        message: result.error,
      });

      return false;
    } else {
      commit('changeProkesSatgas', {
        detail: {
          ...result.data.data,
          id: payload.id,
        },
      });

      return result.data.data;
    }
  },

  async getTodayScreening({ commit, state }) {
    const { data } = state;
    const result = await ApiGetRequest(
      `health-protocol/taskforce/${(data.detail && data.detail.id) ||
        ''}/detail`
    );
    if (result.error) {
      Message({
        type: 'error',
        message: result.error,
      });

      return false;
    } else {
      commit('changeProkesSatgas', {
        today_screening: result.data.data,
      });

      return result.data.data;
    }
  },

  async getHistoryScreening({ commit, state }) {
    const { data } = state;
    const result = await ApiGetRequest(
      `health-protocol/taskforce/${(data.detail && data.detail.id) ||
        ''}/history`
    );
    if (result.error) {
      Message({
        type: 'error',
        message: result.error,
      });

      return false;
    } else {
      commit('changeProkesSatgas', {
        history_screening: result.data.data,
      });

      return result.data.data;
    }
  },

  async getOdpScreening({ commit, state }) {
    const { data } = state;
    const result = await ApiGetRequest(
      `health-protocol/taskforce/${(data.detail && data.detail.id) || ''}/odp`
    );
    if (result.error) {
      Message({
        type: 'error',
        message: result.error,
      });

      return false;
    } else {
      commit('changeProkesSatgas', {
        odp_screening: result.data.data,
      });

      return result.data.data;
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
