import {
  ApiDeleteRequest,
  ApiGetRequest,
  ApiPostRequest,
  ApiPutRequest
} from "../../utils/broker";
import { Message } from "element-ui";
import { HandlerResult } from "../../utils/Api";
import { formDataDefault, isKacabdin, ROLES } from "@/services/employeeService";
import router from "@/router";
import Vue from "vue";
import Swal from "sweetalert2";

const state = {
  data: {
    passwordAdmin: "diskola2024",
    government_id: null,
    itemsAreaCoverage: [],
    filterRoles: [],
    filter: "",
    items: [],
    itemsAll: [],
    limit: 1000,
    page: 1,
    lastPage: 1,
    total: 0,
    errors: [],
    limitAreaCoverage: 5,
    loading: false,
    loadingAreaCoverage: false,
    showmodal: false,
    showmodalAdmin: false,
    searchName: "",
    status: "active",
    typeAction: "read",
    detailFormData: {},
    listRoles: [
      ROLES.kadis,
      ROLES.kacabdin,
      ROLES.admin,
      ROLES.sd,
      ROLES.smp,
      ROLES.sma,
      ROLES.smk,
      ROLES.slb
    ],
    formData: {
      ...formDataDefault
    },
    roles: {
      ...ROLES
    },
    selectTypeSchool: [
      { value: "Gov SD", label: "SD" },
      { value: "Gov SMP", label: "SMP" },
      { value: "Gov SMA", label: "SMA" },
      { value: "Gov SMK", label: "SMK" },
      { value: "Gov SLB", label: "SLB" }
    ]
  }
};

const mutations = {
  changeEmployee(state, payload) {
    state.data = Object.assign({}, state.data, payload);
  },
  changeFormDataEmployeeObjectAssign(state, payload) {
    state.data.formData = Object.assign({}, state.data.formData, payload);
  },
  changeFormDataEmployee(state, payload) {
    state.data.formData = {
      id: payload.id,
      nip: payload.nip,
      name: payload.name,
      email: payload.email,
      phone: payload.phone,
      government_id: payload.government_id,
      city_id: payload.city_id,
      roles: payload.roles
    };
  },

  /**
   * Proses dimana melakukan penambahan data ke items.
   *
   * @function
   * @param {Object} payload - Harus berupa objek. Data yang akan ditambahkan ke items.
   * @param {Object} state - State saat ini.
   * @returns {void}
   */
  ADD_ITEMS(state, payload) {
    state.data.items.push(payload);
  },

  /**
   * Proses pembaruan item di dalam state.items.
   *
   * @function
   * @param {Object} state - State saat ini.
   * @param {Object} payload - Harus berupa objek yang mengandung id item yang akan diperbarui dan data baru.
   */
  UPDATE_ITEMS(state, payload) {
    // Mencari index item yang akan diperbarui berdasarkan id atau properti unik lainnya.
    const index = state.data.items.findIndex(item => item.id === payload.id);

    // Jika item ditemukan, lakukan pembaruan.
    if (index !== -1) {
      state.data.items[index] = payload;
      Vue.set(state.data.items, index, payload);
    }
  },

  /**
   * Proses dimana melakukan penambahan data ke items.
   *
   * @function
   * @param {Object} payload - Harus berupa objek. Data yang akan ditambahkan ke items.
   * @param {Object} state - State saat ini.
   * @returns {void}
   */
  DELETE_ITEMS(state, payload) {
    const { detailFormData } = state.data;
    let id = detailFormData.id;
    if (payload.id) {
      id = payload.id;
    }
    const index = state.data.items.findIndex(item => item.id === id);
    if (index !== -1) {
      state.data.items.splice(index, 1);
    }
  }
};

const actions = {
  actionRead({ commit }) {
    commit("changeEmployee", { typeAction: "read" });
  },
  actionCreate({ commit }) {
    commit("changeEmployee", { formData: {} });
    commit("changeEmployee", { typeAction: "create" });
  },
  actionEdit({ commit }) {
    commit("changeEmployee", { typeAction: "edit" });
  },
  actionDelete({ commit }) {
    commit("changeEmployee", { typeAction: "delete" });
  },

  async getEmployee({ commit, state }, payload) {

    if (state.data.loading) {
      console.log("call enpoint duplicate: STOP");
      return false;
    }

    const { filterRoles, searchName } = state.data;
    commit("changeEmployee", { formData: formDataDefault });
    let government_id = null;
    if (payload && payload.id) {
      government_id = payload.id;
    } else {
      government_id = state.data.government_id;
    }
    const params = {
      limit: state.data.limit,
      page: payload?.page || state.data.page,
      government_id: government_id
    };

    if (searchName) params.q = searchName;
    if (filterRoles) params.roles = filterRoles;

    commit("changeEmployee", {
      loading: true
    });
    const result = await ApiGetRequest("/employee", params);
    commit("changeEmployee", {
      loading: false
    });

    if (result.error || !result) {
      if (result.code === 422) {
        // proses redirect ke path lain
        router.push({ name: "anggotaGoverment" });
        return false;
      }
      commit("changeEmployee", {
        items: [],
        total: 0
      });
      return false;
    }

    commit("changeEmployee", {
      items: result?.data?.data || [],
      total: result?.data?.meta?.total || result?.data?.data.length
    });
    return true;
  },

  async getAreaCoverage({ commit }, government_id) {
    const params = {
      limit: 1,
      page: 1,
      government_id: government_id,
      roles: [ROLES.kacabdin]
    };


    commit("changeEmployee", {
      loadingAreaCoverage: true
    });
    const result = await ApiGetRequest("/employee", params);
    commit("changeEmployee", {
      loadingAreaCoverage: false
    });

    if (result.error || !result) {
      commit("changeEmployee", {
        itemsAreaCoverage: [],
      });
      return false;
    }

    try {
      commit("changeEmployee", {
        itemsAreaCoverage: result?.data?.data[0].department.area_name || [],
      });
    }catch (e) {
      commit("changeEmployee", {
        itemsAreaCoverage: []
      });
    }
    return true;
  },

  async getEmployeeRoleAdmin({ commit }, payload) {
    let government_id = null;
    if (payload && payload.id) {
      government_id = payload.id;
    } else {
      alert("payload.id required");
      return false;
    }
    const params = {
      limit: 0,
      page: 1,
      government_id: government_id,
      roles: ["Admin"]
    };

    const result = await ApiGetRequest("/employee", params);

    if (result.error || !result) {
      if (result.code === 422) {
        return false;
      }
      commit("changeEmployee", {
        itemsAll: []
      });
      return false;
    }

    commit("changeEmployee", {
      itemsAll: result?.data?.data || []
    });
    return true;
  },

  async postEmployee({ commit, state, dispatch }, payload) {
    const {
      email,
      name,
      phone,
      nip,
      roles,
      password,
      city_id
    } = state.data.formData;
    const { government_id } = state.data;
    const formData = {
      government_id: government_id,
      email,
      name,
      phone,
      nip: nip.replace(/\s/g, ""),
      roles,
      password
    };

    if (payload && payload.jenisModal === "Admin") {
      formData.city_id = city_id;
      formData.roles = ["Admin"];
      formData.password = state.data.passwordAdmin;
    }

    commit("changeEmployee", { loading: true });
    const result = await ApiPostRequest("/employee", formData);
    commit("changeEmployee", { loading: false });

    if (result.error || !result) {
      if (result.errorList) {
        let errors = [];
        for (const [key, value] of Object.entries(result.errorList)) {
          errors.push({
            message: value[0],
            path: [key]
          });
        }
        commit("changeEmployee", {
          errors: errors
        });
        return false;
      }
      Message({
        type: "error",
        message: result.error || "Gagagal diproses"
      });
      return false;
    }
    Message({
      type: "success",
      message: "Berhasil diproses"
    });
    if (!HandlerResult(result, "Post Employee")) {
      return true;
    }
    //commit("ADD_ITEMS", result.data.data);
    dispatch("getEmployee");
    commit("changeEmployee", { formData: {} });
    return true;
  },

  async putEmployee({ commit, state }, payload) {
    const { email, name, phone, nip, roles, id, city_id } = state.data.formData;
    const { government_id } = state.data;
    const formData = {
      government_id: government_id,
      email,
      name,
      phone,
      nip,
      roles
    };

    if (payload && payload.jenisModal === "Admin") {
      formData.city_id = city_id;
      formData.roles = ["Admin"];
    }

    //const additionalRoles = ["Gov SD", "Gov SMP", "Gov SMA", "Gov SMK", "Gov SLB"];
    // Cek apakah ada elemen yang cocok
    /*const hasAdminRole = roles.some(role => ROLES_ADMIN.includes(role));

    // Jika ya, tambahkan roles baru
    if (hasAdminRole) {
      formData.roles = [...new Set([...roles, ...ROLES_PETUGAS])];
    }*/

    commit("changeEmployee", {
      loading: true
    });
    const result = await ApiPutRequest(`/employee/${id}`, formData);
    commit("changeEmployee", {
      loading: false
    });

    Swal.close();
    if (result.error || !result) {
      if (result.errorList) {
        let errors = [];
        for (const [key, value] of Object.entries(result.errorList)) {
          errors.push({
            message: value[0],
            path: [key]
          });
        }
        commit("changeEmployee", {
          errors: errors
        });
        return false;
      }
      Message({
        type: "error",
        message: result.error || "Gagagal diproses"
      });
      return false;
    }
    Message({
      type: "success",
      message: "Berhasil diproses"
    });
    if (!HandlerResult(result, "Post Employee")) {
      return true;
    }
    commit("UPDATE_ITEMS", result.data.data);
    commit("changeEmployee", { formData: {} });
    return true;
  },

  async deleteEmployee({ state, commit }, payload) {
    if (!payload.id) {
      Message({
        type: "error",
        message: "ID tidak ditemukan"
      });
      return false;
    }

    // validasi data
    const { items, roles } = state.data;
    const data = items.find(item => item.id === payload.id);
    if (data && data.roles && data.roles.includes(roles.kacabdin)) {
      Message({
        type: "error",
        message: "Kepala Cabang Dinas, Tidak bisa dihapus."
      });
      return false;
    }

    commit("changeEmployee", {
      loading: true
    });

    const result = await ApiDeleteRequest(`/employee/${payload.id}`);
    commit("changeEmployee", {
      loading: false
    });

    if (result.error || !result) {
      if (result.errorList) {
        let errors = [];
        for (const [key, value] of Object.entries(result.errorList)) {
          errors.push({
            message: value[0],
            path: [key]
          });
        }
        commit("changeEmployee", {
          errors: errors
        });
        return false;
      }
      Message({
        type: "error",
        message: result.error || "Gagagal diproses"
      });
      return false;
    }
    Message({
      type: "success",
      message: "Berhasil diproses"
    });
    commit("DELETE_ITEMS", { id: payload.id });
    return true;
  }
};

const getters = {
  employeeGetters: state => {
    const { filter, items } = state.data;
    if (!items.length) {
      return [];
    }
    return items.filter(item => {
      const filterLower = filter.toLowerCase();
      const itemNameLower = item.name.toLowerCase();
      const itemNipLower = item.nip.toLowerCase();
      return (
        itemNameLower.includes(filterLower) ||
        itemNipLower.includes(filterLower)
      );
    });
  },
  regionEmployeeNameGetters: state => {
    const { items } = state.data;
    if (items.length) {
      if (items[0].department.area_name.length) {
        return items[0].department.area_name.map(item => {
          return {
            name: item.name,
            uuid: item.uuid
          };
        });
      }
      return [
        {
          name: items[0].department.city_name,
          uuid: items[0].department.city_id
        }
      ];
    }
    return [
      {
        name: "Kota/Kab Tidak ditemukan",
        uuid: 0
      }
    ];
  },
  isKacabdinGetters: state => {
    try {
      if (!state.data.formData.roles.length) {
        return false;
      }
      return isKacabdin(state.data.formData.roles);
    } catch (e) {
      return false;
    }
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
