import {
  ApiDeleteRequest,
  ApiGetRequest, ApiPostMultipart,
  ApiPostRequest,
  ApiPutRequest
} from "../../utils/broker";
import { Message } from "element-ui";
import { HandlerResult } from "../../utils/Api";
import {
  formDataDefault,
  truncateStringAgendaSurat
} from "../../services/agendaSuratService";
import Vue from "vue";
import Swal from "sweetalert2";
import moment from "moment";

const message403 = "Maaf Hanya dapat diproses Oleh Petugas yang membuat data tersebut";
const message500 = "Laporkan Ke Admin, Terjadi Kesalahan pada layanan. Coba lagi nanti!";

const state = {
  data: {
    government_id: null,
    filterCreatorId: null,
    filterYear: moment().year(),
    sort_field: "date",
    sort_dir: true,
    filter: "",
    items: [],
    itemsAll: [],
    itemsPurposeAll: [],
    limit: 10,
    page: 1,
    lastPage: 1,
    total: 0,
    errors: [],
    loading: false,
    loadingPurposeAll: false,
    showmodal: false,
    typeAction: "read",
    detailFormData: {},
    listLimit: [10, 25, 50, 100],
    formData: {
      id: null,
      uuid: null,
      schoolUuid: null,
      agenda_number: null,
      date: moment().format("DD-MM-YYYY HH:mm:ss"),
      purpose: null,
      subject: null,
      officer: null,
      department: null,
      creator: {},
      updated_at: null,
      created_at: null
    }
  }
};

const mutations = {
  changeAgendaSurat(state, payload) {
    state.data = Object.assign({}, state.data, payload);
  },
  changeLoading(state, payload) {
    state.data.loading = payload;
  },
  changeFormDataAgendaSuratObjectAssign(state, payload) {
    state.data.formData = Object.assign({}, state.data.formData, payload);
  },
  changeFormDataAgendaSurat(state, payload) {
    state.data.formData = {
      id: payload.id,
      agenda_number: payload.agenda_number,
      date: payload.date,
      officer: payload.officer,
      purpose: payload.purpose,
      subject: payload.subject
    };
  },

  changeLoadingPurposeAll(state, payload) {
    state.data.loadingPurposeAll = payload;
  },

  ADD_ITEM_PUPOSEALL(state, purposeItem) {
    if (!state.data.itemsPurposeAll.includes(purposeItem)) {
      state.data.itemsPurposeAll.push(purposeItem);
    }
  },

  /**
   * Proses dimana melakukan penambahan data ke items.
   *
   * @function
   * @param {Object} payload - Harus berupa objek. Data yang akan ditambahkan ke items.
   * @param {Object} state - State saat ini.
   * @returns {void}
   */
  ADD_ITEMS(state, payload) {
    state.data.items.push(payload);
  },

  /**
   * Proses pembaruan item di dalam state.items.
   *
   * @function
   * @param {Object} state - State saat ini.
   * @param {Object} payload - Harus berupa objek yang mengandung id item yang akan diperbarui dan data baru.
   */
  UPDATE_ITEMS(state, payload) {
    // Mencari index item yang akan diperbarui berdasarkan id atau properti unik lainnya.
    const index = state.data.items.findIndex(item => item.id === payload.id);

    // Jika item ditemukan, lakukan pembaruan.
    if (index !== -1) {
      state.data.items[index] = payload;
      Vue.set(state.data.items, index, payload);
    }
  },

  /**
   * Proses dimana melakukan penambahan data ke items.
   *
   * @function
   * @param {Object} payload - Harus berupa objek. Data yang akan ditambahkan ke items.
   * @param {Object} state - State saat ini.
   * @returns {void}
   */
  DELETE_ITEMS(state, payload) {
    const { detailFormData } = state.data;
    let id = detailFormData.id;
    if (payload.id) {
      id = payload.id;
    }
    const index = state.data.items.findIndex(item => item.id === id);
    if (index !== -1) {
      state.data.items.splice(index, 1);
    }
  }
};

const actions = {
  actionRead({ commit }) {
    commit("changeAgendaSurat", { typeAction: "read" });
  },
  actionCreate({ commit }) {
    commit("changeAgendaSurat", {
      formData: {
        date: moment().format("DD-MM-YYYY HH:mm:ss")
      }
    });
    commit("changeAgendaSurat", { typeAction: "create" });
  },
  actionEdit({ commit }) {
    commit("changeAgendaSurat", { typeAction: "edit" });
  },
  actionDelete({ commit }) {
    commit("changeAgendaSurat", { typeAction: "delete" });
  },
  actionUpload({ commit }) {
    commit("changeAgendaSurat", { typeAction: "upload" });
  },

  async getAgendaSurat({ commit, state }, payload) {
    const {
      filter,
      lastPage,
      limit,
      filterYear,
      filterCreatorId,
      sort_dir,
      sort_field,
      page,
      loading
    } = state.data;

    if (loading) {
      console.log("call enpoint duplicate: STOP");
      return false;
    }

    commit("changeAgendaSurat", { formData: formDataDefault });
    const params = {
      limit: limit,
      year: filterYear,
      sort_field: "date",
      sort_dir: "DESC"
    };

    let payloadPage = payload?.page || page;
    if (payloadPage > lastPage && payload?.page) {
      console.log("STOP", payloadPage, lastPage);
      return false;
    }
    params.page = payloadPage;

    if (filter) params.q = filter;
    if (filterCreatorId) params.creator_id = filterCreatorId;
    if (sort_field) {
      params.sort_field = sort_field;
      params.sort_dir = sort_dir ? "desc" : "asc";
    }

    commit("changeLoading", true);
    const result = await ApiGetRequest("/agenda", params);
    //const result = await TestApiGetRequest("http://localhost:3000/api/error403");
    commit("changeLoading", false);

    if (result.error || !result) {
      if (!result || (result && result.code >= 500)) {
        Swal.fire(
          "Laporkan Ke Admin",
          message500,
          "error"
        );
      }
      if (result.code < 500) {
        Swal.fire(
          "Terjadi Masalah",
          result.error || "Gagal Mengambil data",
          "error"
        );
      }
      commit("changeAgendaSurat", {
        items: [],
        total: 0,
        page: 1,
        lastPage: 1
      });
      return false;
    }

    commit("changeAgendaSurat", {
      items: result?.data?.data || [],
      total: result?.data?.meta?.total || result?.data?.data.length,
      page: result?.data?.meta?.current_page || 1,
      lastPage: result?.data?.meta?.last_page || 1
    });
    return true;
  },

  async getPurposeAll({ commit }) {
    const params = {
      limit: 0
    };
    commit("changeLoadingPurposeAll", true);
    const result = await ApiGetRequest("/agenda-purposes", params);
    commit("changeLoadingPurposeAll", false);

    if (result.error || !result) {
      commit("changeAgendaSurat", {
        itemsPurposeAll: []
      });
      return false;
    }

    commit("changeAgendaSurat", {
      itemsPurposeAll: result?.data?.data || []
    });
    return true;
  },

  async postAgendaSurat({ commit, state }) {
    const { subject, purpose } = state.data.formData;
    const s = truncateStringAgendaSurat(subject, "subject");
    const p = truncateStringAgendaSurat(purpose, "purpose");
    const formData = {
      subject: s,
      purpose: p
    };

    commit("changeLoading", true);
    const result = await ApiPostRequest("/agenda", formData);
    commit("changeLoading", false);

    if (result.error || !result) {
      if (!result || (result && result.code >= 500)) {
        Message({
          type: "error",
          message: message500
        });
        return false;
      }

      if (result.errorList) {
        let errors = [];
        for (const [key, value] of Object.entries(result.errorList)) {
          errors.push({
            message: value[0],
            path: [key]
          });
        }
        commit("changeAgendaSurat", {
          errors: errors
        });
        return false;
      }

      Message({
        type: "error",
        message: result.error || "Gagagal diproses"
      });
      return false;
    }

    Message({
      type: "success",
      message: "Berhasil diproses"
    });
    if (!HandlerResult(result, "Post AgendaSurat")) {
      return true;
    }
    commit("ADD_ITEM_PUPOSEALL", formData.purpose);
    commit("ADD_ITEMS", result.data.data);
    //dispatch("getAgendaSurat");
    commit("changeAgendaSurat", { formData: {} });
    return true;
  },

  async putAgendaSurat({ commit, state }) {
    const { subject, purpose, id } = state.data.formData;
    const { loading } = state.data;

    if (loading) {
      console.log("call enpoint duplicate: STOP");
      return false;
    }
    const s = truncateStringAgendaSurat(subject, "subject");
    const p = truncateStringAgendaSurat(purpose, "purpose");
    const formData = {
      subject: s,
      purpose: p
    };

    commit("changeLoading", true);
    const result = await ApiPutRequest(`/agenda/${id}`, formData);
    commit("changeLoading", false);

    Swal.close();
    if (result.error || !result) {
      if (!result || (result && result.code >= 500)) {
        Message({
          type: "error",
          message: message500
        });
        return false;
      }
      if (result.code === 403) {
        Message({
          type: "error",
          message: message403
        });
        return false;
      }

      if (result.errorList) {
        let errors = [];
        for (const [key, value] of Object.entries(result.errorList)) {
          errors.push({
            message: value[0],
            path: [key]
          });
        }
        commit("changeAgendaSurat", {
          errors: errors
        });
        return false;
      }
      Message({
        type: "error",
        message: result.error || "Gagagal diproses"
      });
      return false;
    }
    Message({
      type: "success",
      message: "Berhasil diproses"
    });
    if (!HandlerResult(result, "Put AgendaSurat")) {
      return true;
    }
    commit("ADD_ITEM_PUPOSEALL", formData.purpose);
    commit("UPDATE_ITEMS", result.data.data);
    commit("changeAgendaSurat", { formData: {} });
    return true;
  },

  async postFileAgendaSurat({ commit, state }) {
    const { file, id, schoolUuid } = state.data.formData;

    const formData = new FormData();
    formData.append("agenda_id", id);
    if (file) {
      formData.append("file", file);
    }
    if (schoolUuid) {
      formData.append("school_id", schoolUuid);
    }

    commit("changeLoading", true);

    const result = await ApiPostMultipart(`/agenda/file`, formData);
    commit("changeLoading", false);

    Swal.close();
    if (result.error || !result) {
      if (!result || (result && result.code >= 500)) {
        Message({
          type: "error",
          message: message500
        });
        return false;
      }
      if (result.code === 403) {
        Message({
          type: "error",
          message: message403
        });
        return false;
      }

      Message({
        type: "error",
        message: result.error || "Gagagal diproses"
      });
      return false;
    }
    Message({
      type: "success",
      message: "Berhasil diproses"
    });
    if (!HandlerResult(result, "Put AgendaSurat")) {
      return true;
    }
    commit("UPDATE_ITEMS", result.data.data);
    commit("changeAgendaSurat", { formData: {} });
    return true;
  },

  async deleteAgendaSurat({ commit }, payload) {
    if (!payload.id) {
      Message({
        type: "error",
        message: "ID tidak ditemukan"
      });
      return false;
    }

    // validasi data
    commit("changeAgendaSurat", {
      loading: true
    });

    const result = await ApiDeleteRequest(`/agenda/${payload.id}`);
    commit("changeAgendaSurat", {
      loading: false
    });

    if (result.error || !result) {
      if (!result || (result && result.code >= 500)) {
        Message({
          type: "error",
          message: message500
        });
        return false;
      }

      if (result.code === 403) {
        Message({
          type: "error",
          message: message403
        });
        return false;
      }
      Message({
        type: "error",
        message: result.error || "Gagagal diproses"
      });
      return false;
    }
    Message({
      type: "success",
      message: "Berhasil diproses"
    });
    commit("DELETE_ITEMS", { id: payload.id });
    return true;
  }
};

const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
